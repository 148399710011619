// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useResponsive, ResponsiveType, useQuery, useNavigation} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import ProgressModal from '@shared/design/components/ProgressModal';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import useProgress, {StepType} from '@shared/modules/App/hooks/useProgress';
import CreateCompanyStepKinds from '@shared/modules/Company/enums/CreateCompanyStepKinds';
import CompanyForm, {CompanyFormProps} from '@shared/modules/Company/forms/CompanyForm';
import useCreateCompanyMutation from '@shared/modules/Company/hooks/useCreateCompanyMutation';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';
import SupermoveProductForm, {
  SupermoveProductFormProps,
} from '@shared/modules/SupermoveProduct/forms/SupermoveProductForm';
import CompanyDetailsFields from 'modules/Company/components/CompanyDetailsFields';
import CreateCompanyModalFooter from 'modules/Company/components/CreateCompanyModalFooter';
import CreateCompanyReviewStep from 'modules/Company/components/CreateCompanyReviewStep';
import OfficeProductFields from 'modules/Company/components/OfficeProductFields';
import ProductsOverviewFields from 'modules/Company/components/ProductsOverviewFields';
import SalesProductFields from 'modules/Company/components/SalesProductFields';

const MobilePaddingContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

const StepHeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const StepHeaderDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const STEPS: StepType[] = [
  {
    kind: CreateCompanyStepKinds.COMPANY_DETAILS,
  },
  {
    kind: CreateCompanyStepKinds.PRODUCTS.OVERVIEW,
    steps: [
      {
        kind: CreateCompanyStepKinds.PRODUCTS.OFFICE,
      },
      {
        kind: CreateCompanyStepKinds.PRODUCTS.SALES,
      },
    ],
  },
  {
    kind: CreateCompanyStepKinds.REVIEW,
  },
];

const StepFields = ({
  stepKind,
  form,
  field,
  setCurrentStepKind,
  responsive,
  activePrimaryOrganizationsForCore,
}: {
  stepKind: string;
  form: Form<{companyForm: CompanyFormProps}>;
  field: string;
  setCurrentStepKind: (kind: string) => void;
  responsive: ResponsiveType;
  activePrimaryOrganizationsForCore: OrganizationModel[];
}) => {
  const supermoveProductForms = _.get(form.values, `${field}.supermoveProductForms`);
  const index = _.findIndex(
    supermoveProductForms,
    (supermoveProductForm: SupermoveProductFormProps) =>
      supermoveProductForm.kind === SupermoveProductKind.OFFICE,
  );
  switch (stepKind) {
    case CreateCompanyStepKinds.COMPANY_DETAILS:
      return (
        <MobilePaddingContainer responsive={responsive}>
          <CompanyDetailsFields form={form} field={field} />
        </MobilePaddingContainer>
      );
    case CreateCompanyStepKinds.PRODUCTS.OVERVIEW:
      return (
        <MobilePaddingContainer responsive={responsive}>
          <ProductsOverviewFields
            form={form}
            field={field}
            setCurrentStepKind={setCurrentStepKind}
          />
        </MobilePaddingContainer>
      );
    case CreateCompanyStepKinds.PRODUCTS.OFFICE:
      return (
        <MobilePaddingContainer responsive={responsive}>
          <OfficeProductFields
            form={form}
            field={field}
            supermoveProductFormField={`${field}.supermoveProductForms.${index}`}
            activePrimaryOrganizationsForCore={activePrimaryOrganizationsForCore}
          />
        </MobilePaddingContainer>
      );
    case CreateCompanyStepKinds.PRODUCTS.SALES:
      return (
        <MobilePaddingContainer responsive={responsive}>
          <SalesProductFields form={form} field={field} />
        </MobilePaddingContainer>
      );
    case CreateCompanyStepKinds.REVIEW:
      return (
        <CreateCompanyReviewStep
          form={form}
          field={field}
          setCurrentStepKind={setCurrentStepKind}
        />
      );
    default:
      return null;
  }
};

const CreateCompanyStepFields = ({
  form,
  field,
  currentStepKind,
  setCurrentStepKind,
  activePrimaryOrganizationsForCore,
}: {
  form: Form<{companyForm: CompanyFormProps}>;
  field: string;
  currentStepKind: string;
  setCurrentStepKind: (kind: string) => void;
  activePrimaryOrganizationsForCore: OrganizationModel[];
}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <MobilePaddingContainer responsive={responsive}>
        <StepHeaderText responsive={responsive}>
          {CreateCompanyStepKinds.getStepTitle(currentStepKind)}
        </StepHeaderText>
        <Space height={8} />
        <StepHeaderDescription responsive={responsive}>
          {CreateCompanyStepKinds.getStepDescription(currentStepKind)}
        </StepHeaderDescription>
      </MobilePaddingContainer>
      <Space height={16} />
      <StepFields
        stepKind={currentStepKind}
        form={form}
        field={field}
        setCurrentStepKind={setCurrentStepKind}
        responsive={responsive}
        activePrimaryOrganizationsForCore={activePrimaryOrganizationsForCore}
      />
      {responsive.desktop && <Space height={24} />}
    </React.Fragment>
  );
};

// This allows us to hide product steps when they are inactive.
const getSteps = ({
  steps,
  form,
}: {
  steps: StepType[];
  form: Form<{companyForm: CompanyFormProps}>;
}) => {
  const supermoveProductForms = _.get(form.values, 'companyForm.supermoveProductForms');
  return steps.map((step) => {
    if (step.kind === CreateCompanyStepKinds.PRODUCTS.OVERVIEW && step.steps) {
      return {
        ...step,
        steps: step.steps.filter((step) => {
          switch (step.kind) {
            case CreateCompanyStepKinds.PRODUCTS.OFFICE: {
              const officeForm = _.find(
                supermoveProductForms,
                (supermoveProductForm: SupermoveProductFormProps) =>
                  supermoveProductForm.kind === SupermoveProductKind.OFFICE,
              );
              return officeForm?.status === SupermoveProductStatus.ACTIVE;
            }
            case CreateCompanyStepKinds.PRODUCTS.CREW: {
              const crewForm = _.find(
                supermoveProductForms,
                (supermoveProductForm: SupermoveProductFormProps) =>
                  supermoveProductForm.kind === SupermoveProductKind.CREW,
              );
              return crewForm?.status === SupermoveProductStatus.ACTIVE;
            }
            case CreateCompanyStepKinds.PRODUCTS.SALES: {
              const salesForm = _.find(
                supermoveProductForms,
                (supermoveProductForm: SupermoveProductFormProps) =>
                  supermoveProductForm.kind === SupermoveProductKind.SALES,
              );
              return salesForm?.status === SupermoveProductStatus.ACTIVE;
            }
            default:
              return true;
          }
        }),
      };
    }
    return step;
  });
};

const CreateCompanyModal = ({isOpen, handleClose}: {isOpen: boolean; handleClose: () => void}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigation();
  const {loading, data} = useQuery(CreateCompanyModal.query, {fetchPolicy: 'cache-and-network'});
  const companyForm = CompanyForm.new({
    supermoveProductForms: [
      SupermoveProductForm.new({
        kind: SupermoveProductKind.OFFICE,
        status: SupermoveProductStatus.INACTIVE,
      }),
      SupermoveProductForm.new({
        kind: SupermoveProductKind.CREW,
        status: SupermoveProductStatus.INACTIVE,
      }),
      SupermoveProductForm.new({
        kind: SupermoveProductKind.SALES,
        status: SupermoveProductStatus.INACTIVE,
      }),
    ],
  });
  const {form, handleSubmit, submitting} = useCreateCompanyMutation({
    companyForm,
    onSuccess: ({company}) => {
      if (responsive.desktop) {
        navigator.navigate('CompanyDetailsOverviewPage', {slug: company.slug});
      } else {
        navigator.navigate('CompanyDetailsPageMobileNavigation', {slug: company.slug});
      }
    },
    onError: (errors) => console.log({errors}),
  });

  const {
    currentStepKind,
    setCurrentStepKind,
    isViewingFirstStep,
    previousStepKind,
    nextStepKind,
    completedSteps,
    setCompletedSteps,
    allStepKinds,
    nextStepToComplete,
    currentStepIndex,
  } = useProgress({
    steps: STEPS,
    getPreviousStepKind: CreateCompanyStepKinds.getPreviousStepKind,
    getNextStepKind: CreateCompanyStepKinds.getNextStepKind,
  });

  // If all steps are completed, we are reviewing, allowing the user to easily navigate to the review step.
  const isReviewingSteps = _.isEqual(
    _.sortBy(allStepKinds.filter((stepKind) => stepKind !== CreateCompanyStepKinds.REVIEW)),
    _.sortBy(completedSteps),
  );

  return (
    <ProgressModal
      isOpen={isOpen}
      handleClose={handleClose}
      steps={getSteps({steps: STEPS, form})}
      completedSteps={completedSteps}
      isViewingFirstStep={isViewingFirstStep}
      currentStepKind={currentStepKind}
      getStepTitle={CreateCompanyStepKinds.getStepTitle}
      setCurrentStepKind={setCurrentStepKind}
      nextStepToComplete={nextStepToComplete}
      currentStepIndex={currentStepIndex}
      title={'Create Company'}
      handleBack={
        isViewingFirstStep
          ? null
          : () => {
              previousStepKind && setCurrentStepKind(previousStepKind);
            }
      }
      BodyElement={
        <Loading loading={loading} as={PageLoadingIndicator}>
          {() => (
            <CreateCompanyStepFields
              form={form}
              field={'companyForm'}
              currentStepKind={currentStepKind}
              setCurrentStepKind={setCurrentStepKind}
              activePrimaryOrganizationsForCore={data.activePrimaryOrganizationsForCore}
            />
          )}
        </Loading>
      }
      FooterElement={
        <CreateCompanyModalFooter
          isViewingFirstStep={isViewingFirstStep}
          previousStepKind={previousStepKind}
          nextStepKind={nextStepKind}
          currentStepKind={currentStepKind}
          setCurrentStepKind={setCurrentStepKind}
          setCompletedSteps={setCompletedSteps}
          form={form}
          isReviewingSteps={isReviewingSteps}
          handleSubmit={handleSubmit}
          submitting={submitting}
          existingSalesforceIds={
            data?.activePrimaryOrganizationsForCore
              .filter(
                (activePrimaryOrganization: OrganizationModel) =>
                  activePrimaryOrganization.company.salesforceId,
              )
              .map(
                (activePrimaryOrganization: OrganizationModel) =>
                  activePrimaryOrganization.company.salesforceId,
              ) || []
          }
        />
      }
    />
  );
};

// ------------------------------
// Data
// ------------------------------
CreateCompanyModal.query = gql`
  ${OfficeProductFields.fragment}
  query CreateCompanyModal {
    activePrimaryOrganizationsForCore: activePrimaryOrganizationsForProduct(kind: "OFFICE") {
      id
      company {
        id
        salesforceId
      }
      ...OfficeProductFields
    }
  }
`;

export default CreateCompanyModal;
