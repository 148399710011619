import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useResponsive, useMemo, ResponsiveType, Form} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import ProgressModal from '@shared/design/components/ProgressModal';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import useProgress from '@shared/modules/App/hooks/useProgress';
import AddCoreAppsToCompanyStep from '@shared/modules/SupermoveProduct/enums/AddCoreAppsToCompanyStep';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';
import SupermoveProductForm, {
  SupermoveProductFormProps,
} from '@shared/modules/SupermoveProduct/forms/SupermoveProductForm';
import useUpsertSupermoveProductMutation from '@shared/modules/SupermoveProduct/hooks/useUpsertSupermoveProductMutation';
import OfficeProductFields from 'modules/Company/components/OfficeProductFields';

const MobilePaddingContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

const StepHeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const StepHeaderDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const STEPS = AddCoreAppsToCompanyStep.STEPS.map((step) => ({
  kind: step.kind,
}));

const ProgressFooter = ({
  handleSubmit,
  isSubmitting,
}: {
  handleSubmit: () => void;
  isSubmitting: boolean;
}) => {
  return <Button text={'Submit'} isSubmitting={isSubmitting} onPress={handleSubmit} />;
};

const StepFields = ({
  stepKind,
  form,
  field,
  responsive,
  activePrimaryOrganizationsForCore,
}: {
  stepKind: string;
  form: Form<{supermoveProductForm: SupermoveProductFormProps}>;
  field: string;
  responsive: ResponsiveType;
  activePrimaryOrganizationsForCore: OrganizationModel[];
}) => {
  switch (stepKind) {
    case AddCoreAppsToCompanyStep.OFFICE_APP:
      return (
        <MobilePaddingContainer responsive={responsive}>
          <OfficeProductFields
            form={form}
            field={field}
            supermoveProductFormField={field}
            activePrimaryOrganizationsForCore={activePrimaryOrganizationsForCore}
          />
        </MobilePaddingContainer>
      );
    default:
      return null;
  }
};

const AddCoreAppsToCompanyModalBody = ({
  form,
  field,
  currentStep,
  currentStepKind,
  activePrimaryOrganizationsForCore,
}: {
  form: Form<{supermoveProductForm: SupermoveProductFormProps}>;
  field: string;
  currentStep: {title: string; description: string};
  currentStepKind: string;
  activePrimaryOrganizationsForCore: OrganizationModel[];
}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <MobilePaddingContainer responsive={responsive}>
        <StepHeaderText responsive={responsive}>{currentStep.title}</StepHeaderText>
        <Space height={8} />
        <StepHeaderDescription responsive={responsive}>
          {currentStep.description}
        </StepHeaderDescription>
      </MobilePaddingContainer>
      <Space height={16} />
      <StepFields
        stepKind={currentStepKind}
        form={form}
        field={field}
        responsive={responsive}
        activePrimaryOrganizationsForCore={activePrimaryOrganizationsForCore}
      />
      {responsive.desktop && <Space height={24} />}
    </React.Fragment>
  );
};

const AddCoreAppsToCompanyModal = ({
  isOpen,
  handleClose,
  refetch,
  companyId,
}: {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
  companyId: string;
}) => {
  const {loading, data} = useQuery(AddCoreAppsToCompanyModal.query, {
    fetchPolicy: 'cache-and-network',
  });
  const supermoveProductForm = SupermoveProductForm.new({
    kind: SupermoveProductKind.OFFICE,
    status: SupermoveProductStatus.ACTIVE,
    companyId,
  });

  const {form, handleSubmit, submitting} = useUpsertSupermoveProductMutation({
    supermoveProductForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  const {
    currentStepKind,
    setCurrentStepKind,
    isViewingFirstStep,
    previousStepKind,
    completedSteps,
    nextStepToComplete,
    currentStepIndex,
  } = useProgress({
    steps: STEPS,
    getPreviousStepKind: AddCoreAppsToCompanyStep.getPreviousStepKind,
    getNextStepKind: AddCoreAppsToCompanyStep.getNextStepKind,
  });

  const currentStep = useMemo(
    () => AddCoreAppsToCompanyStep.getStep(currentStepKind),
    [currentStepKind],
  );

  return (
    <ProgressModal
      isOpen={isOpen}
      handleClose={handleClose}
      steps={STEPS}
      completedSteps={completedSteps}
      isViewingFirstStep={isViewingFirstStep}
      currentStepKind={currentStepKind}
      getStepTitle={(kind) => AddCoreAppsToCompanyStep.getStep(kind).title}
      setCurrentStepKind={setCurrentStepKind}
      nextStepToComplete={nextStepToComplete}
      currentStepIndex={currentStepIndex}
      title={'Set Up Office App'}
      handleBack={
        isViewingFirstStep
          ? null
          : () => {
              previousStepKind && setCurrentStepKind(previousStepKind);
            }
      }
      BodyElement={
        <Loading loading={loading} as={PageLoadingIndicator}>
          {() => (
            <AddCoreAppsToCompanyModalBody
              form={form}
              field={'supermoveProductForm'}
              currentStep={currentStep}
              currentStepKind={currentStepKind}
              activePrimaryOrganizationsForCore={data.activePrimaryOrganizationsForCore}
            />
          )}
        </Loading>
      }
      FooterElement={<ProgressFooter handleSubmit={handleSubmit} isSubmitting={submitting} />}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddCoreAppsToCompanyModal.query = gql`
  ${OfficeProductFields.fragment}
  query CreateCompanyModal {
    activePrimaryOrganizationsForCore: activePrimaryOrganizationsForProduct(kind: "OFFICE") {
      id
      ...OfficeProductFields
    }
  }
`;

export default AddCoreAppsToCompanyModal;
