// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useNavigation,
  useQuery,
  useResponsive,
  ResponsiveType,
  useInlineFormMutation,
} from '@supermove/hooks';
import {SettingsModel, UserModel} from '@supermove/models';

// App
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import SettingsSection from '@shared/modules/Settings/components/SettingsSection';
import SettingsSectionOption from '@shared/modules/Settings/components/SettingsSectionOption';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';

const PaymentsSettingsContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

const PaymentsSettings = ({
  settings,
  viewer,
  refetch,
}: {
  settings: SettingsModel;
  viewer: UserModel;
  refetch: () => void;
}) => {
  const toggleSettingsIsStripePayengineGatewayEnabledMutation = useInlineFormMutation({
    name: 'settingsForm',
    form: {
      settingsId: settings.id,
      updatedById: viewer.id,
    },
    mutation: gql`
      mutation useToggleSettingsIsStripePayengineGatewayEnabledMutation($settingsForm: SettingsForm!) {
        response: toggleSettingsIsStripePayengineGatewayEnabled(settingsForm: $settingsForm) {
          ${gql.errors}
          settings {
            id
            isStripePayengineGatewayEnabled
          }
        }
      }
    `,
    onSuccess: refetch,
  });
  const toggleSettingsIsAuthorizeDotNetPayengineGatewayEnabledMutation = useInlineFormMutation({
    name: 'settingsForm',
    form: {
      settingsId: settings.id,
      updatedById: viewer.id,
    },
    mutation: gql`
      mutation useToggleSettingsIsAuthorizeDotNetPayengineGatewayEnabledMutation($settingsForm: SettingsForm!) {
        response: toggleSettingsIsAuthorizeDotNetPayengineGatewayEnabled(settingsForm: $settingsForm) {
          ${gql.errors}
          settings {
            id
            isAuthorizeDotNetPayengineGatewayEnabled
          }
        }
      }
    `,
    onSuccess: refetch,
  });
  return (
    <SettingsSection>
      <SettingsSectionOption
        name={'Stripe'}
        description={'Allows the company to connect a Stripe account to use on Supermove.'}
        isEnabled={settings.isStripePayengineGatewayEnabled}
        onPress={toggleSettingsIsStripePayengineGatewayEnabledMutation.handleSubmit}
      />
      <Space height={16} />
      <SettingsSectionOption
        name={'Authorize.net'}
        description={'Allows the company to connect a Authorize.net account to use on Supermove.'}
        isEnabled={settings.isAuthorizeDotNetPayengineGatewayEnabled}
        onPress={toggleSettingsIsAuthorizeDotNetPayengineGatewayEnabledMutation.handleSubmit}
      />
    </SettingsSection>
  );
};

const CompanyModulesPaymentsPage = () => {
  const {params} = useNavigation();
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(CompanyModulesPaymentsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });

  return (
    <CompanyDetailsPage
      selectedLabel={'modules/payments'}
      pageTitle={'Payments'}
      pageDescription={
        'Configure payments and any integrated features. These changes will apply to the company and, if applicable, its branches.'
      }
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <PaymentsSettingsContainer responsive={responsive}>
            <PaymentsSettings
              settings={data.company.primaryOrganization.settings}
              viewer={data.viewer}
              refetch={refetch}
            />
          </PaymentsSettingsContainer>
        )}
      </Loading>
    </CompanyDetailsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyModulesPaymentsPage.query = gql`
  query CompanyModulesPaymentsPage($slug: String!) {
    ${gql.query}
    viewer {
      id
    }
    company(slug: $slug) {
      id
      primaryOrganization {
        id
        settings {
          id
          isStripePayengineGatewayEnabled
          isAuthorizeDotNetPayengineGatewayEnabled
        }
      }
    }
  }
`;

export default CompanyModulesPaymentsPage;
