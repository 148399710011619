// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery, useDrawer} from '@supermove/hooks';
import {Organization, PhoneNumberModel, CompanyModel} from '@supermove/models';
import {Datetime, Phone} from '@supermove/utils';

// App
import Table from '@shared/design/components/Table';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';
import ChangePrimaryPhoneNumberDrawer from 'modules/Company/CompanyDetails/components/ChangePrimaryPhoneNumberDrawer';
import UpdatePrimaryPhoneForwardingPhoneNumberDrawer from 'modules/Company/CompanyDetails/components/UpdatePrimaryPhoneForwardingPhoneNumberDrawer';

const getColumnActions = ({
  phoneNumber,
  refetch,
}: {
  phoneNumber: PhoneNumberModel;
  refetch: () => void;
}) => {
  return [
    {
      text: 'Update phone number',
      onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
      actionHook: {
        hook: useDrawer,
        hookArgument: {
          name: `Change Primary Phone Number: ${phoneNumber.organization.name}`,
        },
        renderComponent: ({
          isOpen,
          handleClose,
          hookKey,
        }: {
          isOpen: boolean;
          handleClose: () => void;
          hookKey: string;
        }) => {
          return (
            <ChangePrimaryPhoneNumberDrawer
              key={hookKey}
              isOpen={isOpen}
              handleClose={handleClose}
              refetch={refetch}
              phoneNumber={phoneNumber}
              organizationId={phoneNumber.organization.id}
            />
          );
        },
      },
    },
  ];
};

const getColumnActionsV2 = ({
  phoneNumber,
  refetch,
}: {
  phoneNumber: PhoneNumberModel;
  refetch: () => void;
}) => {
  return [
    {
      text: 'Update phone number',
      onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
      actionHook: {
        hook: useDrawer,
        hookArgument: {
          name: `Change Primary Phone Number: ${phoneNumber.organization.name}`,
        },
        renderComponent: ({
          isOpen,
          handleClose,
          hookKey,
        }: {
          isOpen: boolean;
          handleClose: () => void;
          hookKey: string;
        }) => {
          return (
            <ChangePrimaryPhoneNumberDrawer
              key={hookKey}
              isOpen={isOpen}
              handleClose={handleClose}
              refetch={refetch}
              phoneNumber={phoneNumber}
              organizationId={phoneNumber.organization.id}
            />
          );
        },
      },
    },
    {
      text: 'Update forwarding phone number',
      onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
      actionHook: {
        hook: useDrawer,
        hookArgument: {
          name: `Update Forwarding Phone Number: ${phoneNumber.organization.name}`,
        },
        renderComponent: ({
          isOpen,
          handleClose,
          hookKey,
        }: {
          isOpen: boolean;
          handleClose: () => void;
          hookKey: string;
        }) => {
          return (
            <UpdatePrimaryPhoneForwardingPhoneNumberDrawer
              key={hookKey}
              isOpen={isOpen}
              handleClose={handleClose}
              refetch={refetch}
              forwardingPhoneNumber={phoneNumber.organization.settings.forwardingPhoneNumber}
              organizationId={phoneNumber.organization.id}
            />
          );
        },
      },
    },
  ];
};

const getColumnDefinitions = ({refetch}: {refetch: () => void}) => {
  return [
    {
      headerLabel: 'Phone Number',
      flex: 1,
      cellText: (phoneNumber: PhoneNumberModel) => Phone.display(phoneNumber.number),
    },
    {
      headerLabel: 'Branch Name',
      flex: 1,
      cellText: (phoneNumber: PhoneNumberModel) => phoneNumber.organization.name,
      secondary: {
        headerLabel: 'Branch Type',
        cellText: (phoneNumber: PhoneNumberModel) => {
          const organizationKind = phoneNumber.organization.kind;
          return Organization.getBranchTypeForOrganization({kind: organizationKind});
        },
      },
    },
    {
      headerLabel: 'Last Updated',
      flex: 1,
      cellText: (phoneNumber: PhoneNumberModel) =>
        Datetime.convertToDisplayDatetime(phoneNumber.createdAt, Datetime.DISPLAY_DATETIME),
      secondary: {
        headerLabel: 'Updated by',
        cellText: (phoneNumber: PhoneNumberModel) =>
          phoneNumber.createdBy ? phoneNumber.createdBy.fullName : '',
      },
    },
    {
      flex: 0.1,
      actions: (phoneNumber: PhoneNumberModel) => getColumnActions({phoneNumber, refetch}),
    },
  ];
};

const getColumnDefinitionsV2 = ({refetch}: {refetch: () => void}) => {
  return [
    {
      headerLabel: 'Phone Number',
      flex: 1,
      cellText: (phoneNumber: PhoneNumberModel) => Phone.display(phoneNumber.number),
    },
    {
      headerLabel: 'Branch Name',
      flex: 1,
      cellText: (phoneNumber: PhoneNumberModel) => phoneNumber.organization.name,
      secondary: {
        headerLabel: 'Branch Type',
        cellText: (phoneNumber: PhoneNumberModel) => {
          const organizationKind = phoneNumber.organization.kind;
          return Organization.getBranchTypeForOrganization({kind: organizationKind});
        },
      },
    },
    {
      headerLabel: 'Forwarding Phone Number',
      flex: 1,
      cellText: (phoneNumber: PhoneNumberModel) =>
        Phone.display(phoneNumber.organization.settings.forwardingPhoneNumber),
    },
    {
      headerLabel: 'Last Updated',
      flex: 1,
      cellText: (phoneNumber: PhoneNumberModel) =>
        Datetime.convertToDisplayDatetime(phoneNumber.createdAt, Datetime.DISPLAY_DATETIME),
      secondary: {
        headerLabel: 'Updated by',
        cellText: (phoneNumber: PhoneNumberModel) =>
          phoneNumber.createdBy ? phoneNumber.createdBy.fullName : '',
      },
    },
    {
      flex: 0.1,
      actions: (phoneNumber: PhoneNumberModel) => getColumnActionsV2({phoneNumber, refetch}),
    },
  ];
};

const CompanyDetailsOfficePhoneNumbersPageContent = ({
  company,
  refetch,
}: {
  company: CompanyModel;
  refetch: () => void;
}) => {
  const primaryPhoneNumbers = company.primaryPhoneNumbers || [];
  const columnDefinitions = company.primaryOrganization.features
    .isEnabledAllowPrimaryPhoneNumberCallForwarding
    ? getColumnDefinitionsV2({refetch})
    : getColumnDefinitions({refetch});
  return (
    <Table
      items={primaryPhoneNumbers}
      itemKey={'id'}
      columnDefinitions={columnDefinitions}
      hasBorder
    />
  );
};

const CompanyDetailsOfficePhoneNumbersPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(CompanyDetailsOfficePhoneNumbersPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });

  return (
    <CompanyDetailsPage
      selectedLabel={'office-app/phone-numbers'}
      pageTitle={'Phone Numbers'}
      pageDescription={'View and manage the phone numbers associated with this company.'}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          const {company} = data;
          return (
            <CompanyDetailsOfficePhoneNumbersPageContent company={company} refetch={refetch} />
          );
        }}
      </Loading>
    </CompanyDetailsPage>
  );
};

CompanyDetailsOfficePhoneNumbersPage.query = gql`
  ${UpdatePrimaryPhoneForwardingPhoneNumberDrawer.fragment}

  query CompanyDetailsOfficePhoneNumbersPage($slug: String!) {
    ${gql.query}
    company(slug: $slug) {
      id
      primaryOrganization {
        id
        features {
          isEnabledAllowPrimaryPhoneNumberCallForwarding: isEnabled(feature: "ALLOW_PRIMARY_PHONE_NUMBER_CALL_FORWARDING")
        }
      }
      primaryPhoneNumbers {
        id
        number
        createdAt
        organization {
          id
          name
          kind
          settings {
            id
            forwardingPhoneNumber
            ...UpdatePrimaryPhoneForwardingPhoneNumberDrawer
          }
        }
        createdBy {
          id
          fullName
        }
      }
    }
  }
`;

export default CompanyDetailsOfficePhoneNumbersPage;
