// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useLazyQuery, useState, useResponsive, Form} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import EmptyState from '@shared/design/components/EmptyState';
import SearchBar from '@shared/design/components/SearchBar';
import Line from '@shared/modules/App/components/Line';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import {ChangePrimaryPhoneNumberFormWrappedType} from '@shared/modules/Organization/hooks/useChangePrimaryPhoneNumberMutation';

const Fields = Styled.View`
`;

const Header = Styled.View`
  flex-direction: row;
`;

const RowTouchable = Styled.Touchable`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const RowContent = Styled.View`
`;

const Label = Styled.Text`
  ${Typography.Label};
  color: ${({color}: {color: string}) => color};
`;

const EmptyStateContainer = Styled.View`
  height: 200px;
  justify-content: center;
  align-items: center;
`;

interface TwilioPhoneNumber {
  phoneNumber: string;
}

const NoTwilioPhoneNumbers = () => {
  return (
    <EmptyStateContainer>
      <EmptyState
        title={'No phone numbers found.'}
        message={'Please enter a different area code.'}
      />
    </EmptyStateContainer>
  );
};

const TwilioPhoneNumberItem = ({
  index,
  field,
  twilioPhoneNumber,
  selectedPhoneNumber,
  form,
}: {
  index: number;
  field: string;
  twilioPhoneNumber: TwilioPhoneNumber;
  selectedPhoneNumber: string;
  form: Form<ChangePrimaryPhoneNumberFormWrappedType>;
}) => {
  const phoneNumber = twilioPhoneNumber.phoneNumber.slice(-10);
  const isSelected = selectedPhoneNumber === phoneNumber;

  return (
    <React.Fragment key={index}>
      <RowTouchable onPress={() => form.setFieldValue(field, phoneNumber)}>
        <Space height={8} />
        <Row>
          <Label color={isSelected ? colors.blue.interactive : colors.black}>
            {Phone.display(phoneNumber)}
          </Label>
          <RowContent>
            {isSelected && (
              <React.Fragment>
                <Icon source={Icon.CheckCircle} color={colors.blue.interactive} size={16} />
              </React.Fragment>
            )}
          </RowContent>
        </Row>
        <Space height={8} />
      </RowTouchable>
      <Line />
    </React.Fragment>
  );
};

const TwilioPhoneNumbersList = ({
  form,
  field,
  twilioPhoneNumbers,
}: {
  form: Form<ChangePrimaryPhoneNumberFormWrappedType>;
  field: string;
  twilioPhoneNumbers: TwilioPhoneNumber[];
}) => {
  const selectedPhoneNumber = _.get(form.values, field);

  return (
    <FlatList
      data={twilioPhoneNumbers}
      listKey={`${field}-twilio-phone-numbers`}
      keyExtractor={(twilioPhoneNumber) => twilioPhoneNumber.phoneNumber}
      renderItem={({item: twilioPhoneNumber, index}) => (
        <TwilioPhoneNumberItem
          key={index}
          index={index}
          field={field}
          twilioPhoneNumber={twilioPhoneNumber}
          selectedPhoneNumber={selectedPhoneNumber}
          form={form}
        />
      )}
      ListHeaderComponent={() => <Line />}
      ListEmptyComponent={NoTwilioPhoneNumbers}
    />
  );
};

interface PrimaryPhoneNumberFieldsProps {
  form: Form<ChangePrimaryPhoneNumberFormWrappedType>;
  field: string;
  phoneNumber?: string;
}

const PrimaryPhoneNumberFields = ({form, field, phoneNumber}: PrimaryPhoneNumberFieldsProps) => {
  const responsive = useResponsive();
  const phoneNumberFieldError = _.get(form.errors, `${field}.phoneNumber`);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [fetchQuery, {loading, data}] = useLazyQuery(PrimaryPhoneNumberFields.query, {
    fetchPolicy: 'network-only',
    variables: {
      countryCode: 'US',
      areaCode: '',
      page: 1,
      perPage: 20,
    },
  });

  // @ts-ignore
  const handleSubmit = () => fetchQuery({variables: {areaCode: searchQuery}});

  return (
    <Fields>
      {phoneNumberFieldError && (
        <React.Fragment>
          <ErrorCallout text={phoneNumberFieldError as string} />
          <Space height={16} />
        </React.Fragment>
      )}
      <Header>
        <SearchBar
          placeholder={'Search area code'}
          defaultValue={searchQuery}
          onChangeText={setSearchQuery}
          onEnterPress={handleSubmit}
          isResponsive
          style={{flex: 1}}
        />
        <Space width={16} />
        <Button
          text={responsive.desktop ? 'Find Number' : 'Search'}
          onPress={handleSubmit}
          isResponsive
          isSubmitting={loading}
        />
      </Header>
      <Space height={16} />
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <TwilioPhoneNumbersList
            form={form}
            field={`${field}.phoneNumber`}
            twilioPhoneNumbers={data ? data.twilioPhoneNumbers : []}
          />
        )}
      </Loading>
    </Fields>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PrimaryPhoneNumberFields.query = gql`
  query PrimaryPhoneNumberFields($countryCode: String!, $areaCode: String!, $page: Int!, $perPage: Int!) {
    ${gql.query}
    twilioPhoneNumbers: searchAvailableTwilioPrimaryPhoneNumbers(
      countryCode: $countryCode,
      areaCode: $areaCode,
      page: $page,
      perPage: $perPage,
    ) {
      phoneNumber
    }
  }
`;

export default PrimaryPhoneNumberFields;
