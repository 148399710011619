// Supermove
import {gql} from '@supermove/graphql';
import {OrganizationModel} from '@supermove/models';

// App
import {NavigationItem} from 'core/NavigationItems';

/**
 * Retrieves Company Details navigation items.
 */
const getItems = (organization: OrganizationModel): NavigationItem[] => {
  return [
    {
      label: 'Company',
      value: 'COMPANY',
      valueKey: 'section',
      items: [
        {
          label: 'Overview',
          value: 'CompanyDetailsOverviewPage',
          valueKey: 'route',
        },
        {
          label: 'Products',
          value: 'CompanyDetailsProductsPage',
          valueKey: 'route',
        },
        {
          label: 'Branches',
          value: 'CompanyDetailsBranchesPage',
          valueKey: 'route',
        },
      ],
    },
    {
      label: 'Modules',
      value: 'MODULES',
      valueKey: 'section',
      items: [
        {
          label: 'Calendars',
          value: 'CompanyModulesCalendarsPage',
          valueKey: 'route',
        },
        {
          label: 'Communication',
          value: 'CompanyModulesCommunicationPage',
          valueKey: 'route',
        },
        {
          label: 'Equipment & Materials',
          value: 'CompanyModulesEquipmentAndMaterialsPage',
          valueKey: 'route',
        },
        {
          label: 'Inventory',
          value: 'CompanyModulesInventoryPage',
          valueKey: 'route',
        },
        {
          label: 'Payments',
          value: 'CompanyModulesPaymentsPage',
          valueKey: 'route',
        },
        {
          label: 'Projects',
          value: 'CompanyModulesProjectsPage',
          valueKey: 'route',
        },
        {
          label: 'Storage',
          value: 'CompanyModulesStoragePage',
          valueKey: 'route',
        },
        {
          label: 'Timesheets',
          value: 'CompanyModulesTimesheetsPage',
          valueKey: 'route',
        },
      ],
    },
    {
      label: 'Office App',
      value: 'OFFICE_APP',
      valueKey: 'section',
      items: [
        {
          label: 'General',
          value: 'CompanyDetailsOfficeAppGeneralPage',
          valueKey: 'route',
        },
        ...(organization.features.isEnabledChangePrimaryPhoneNumber
          ? [
              {
                label: 'Phone Numbers',
                value: 'CompanyDetailsOfficePhoneNumbersPage',
                valueKey: 'route',
              } as NavigationItem,
            ]
          : []),
      ],
    },
    {
      label: 'AI Sales Copilot',
      value: 'AI_SALES_COPILOT',
      valueKey: 'section',
      items: [
        {
          label: 'General',
          value: 'CompanyDetailsAiSalesCopilotGeneralPage',
          valueKey: 'route',
        },
      ],
    },
  ];
};

// --------------------------------------------------
// Data
// --------------------------------------------------
getItems.fragment = gql`
  fragment CompanyDetailsNavigation_getItems on Organization {
    id
    features {
      isEnabledChangePrimaryPhoneNumber: isEnabled(feature: "ADMIN_CHANGE_PRIMARY_PHONE_NUMBER")
    }
  }
`;

const CompanyDetailsNavigation = {
  getItems,
};

export default CompanyDetailsNavigation;
