// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useMountEffect, useNavigation} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import AdminAppPageHeader from 'modules/App/components/AdminAppPageHeader';
import useAppContext from 'modules/App/context/useAppContext';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const AdminAppPage = ({
  title,
  description,
  previousTitle,
  handlePrevious,
  HeaderActionElement,
  handleSearch,
  searchQuery,
  searchPlaceholder,
  HeaderElement,
  isLoading,
  headerStyle,
  containerStyle,
  children,
}: {
  title?: string;
  description?: string;
  previousTitle?: string;
  handlePrevious?: () => void;
  HeaderActionElement?: React.ReactNode;
  handleSearch?: (text: string) => void;
  searchQuery?: string;
  searchPlaceholder?: string;
  HeaderElement?: React.ReactNode;
  isLoading?: boolean;
  headerStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  children?: React.ReactNode;
}) => {
  const {navigator} = useNavigation();
  const {setGlobalNavigationTab, globalNavigationTab} = useAppContext();
  useMountEffect(() => {
    // Set the global navigation tab based on the current route if not already set
    // This is to ensure that the correct tab is highlighted when the user navigates to a page directly
    const {routeName} = navigator.state;
    if (routeName !== '' && routeName !== globalNavigationTab) {
      setGlobalNavigationTab(routeName);
    }
  });

  return (
    <Container style={containerStyle}>
      {HeaderElement || (
        <AdminAppPageHeader
          title={title}
          description={description}
          previousTitle={previousTitle}
          handlePrevious={handlePrevious}
          HeaderActionElement={HeaderActionElement}
          handleSearch={handleSearch}
          searchQuery={searchQuery}
          searchPlaceholder={searchPlaceholder}
          style={headerStyle}
        />
      )}
      {isLoading ? <PageLoadingIndicator /> : children}
    </Container>
  );
};

export default AdminAppPage;
