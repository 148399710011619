// Libraries
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {useQuery, useResponsive} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import FieldInput from '@shared/design/components/Field/FieldInput';
import {LeadProviderGlobalIntegration} from 'modules/LeadProvider/components/types';
import useUpdateLeadProviderGlobalIntegrationMutation from 'modules/LeadProvider/components/useUpdateLeadProviderGlobalIntegrationMutation';

const Container = Styled.View``;

const UpdateLeadProviderDrawer = ({
  integration,
  isOpen,
  handleClose,
  refetch,
}: {
  integration: LeadProviderGlobalIntegration;
  isOpen: boolean;
  handleClose: () => void;
  refetch: ReturnType<typeof useQuery>['refetch'];
}) => {
  const responsive = useResponsive();
  const {form, submitting, handleSubmit} = useUpdateLeadProviderGlobalIntegrationMutation({
    updateLeadProviderGlobalIntegrationForm: {
      leadProviderGlobalIntegrationId: integration.id,
      name: integration.name,
      description: integration.description,
      status: integration.status,
      url: integration.url,
    },
    onSuccess: () => {
      form.resetForm();
      handleClose();
      refetch();
    },
    onError: (errors: unknown) => console.error({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Update Lead Provider'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <Container responsive={responsive}>
        <FieldInput
          {...form}
          label={'Name'}
          isResponsive
          isRequired
          input={{
            placeholder: 'Name of this lead provider',
          }}
          name={`updateLeadProviderGlobalIntegrationForm.name`}
        />
        <Space height={16} />
        <FieldInput
          {...form}
          label={'Description'}
          isResponsive
          input={{
            multiline: true,
            placeholder: 'Description of this lead provider',
            style: {height: 240, paddingTop: 8},
          }}
          name={`updateLeadProviderGlobalIntegrationForm.description`}
        />
        <Space height={16} />
        <FieldInput
          {...form}
          label={'URL'}
          isResponsive
          isRequired
          input={{
            placeholder: 'Zapier hook URL for this lead provider',
          }}
          name={`updateLeadProviderGlobalIntegrationForm.url`}
        />
      </Container>
    </DrawerWithAction>
  );
};

export default UpdateLeadProviderDrawer;
