// Libraries
import React from 'react';

// Supermove
import {Styled, ScrollView, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, useNavigation} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import TreeNavigation from '@shared/design/components/TreeNavigation';
import NavigationItems from 'core/NavigationItems';
import AdminAppPage from 'modules/App/components/AdminAppPage';
import CompanyDetailsNavigation from 'modules/Company/CompanyDetails/CompanyDetailsNavigation';
import CompanyPageContentHeader from 'modules/Company/CompanyDetails/components/CompanyPageContentHeader';

const ContentContainer = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const ContentBodyContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const VerticalDivider = Styled.View`
  height: 100%;
  width: 1px;
  background-color: ${colors.gray.border};
`;

const NavigationContainer = Styled.View`
  width: 260px;
  background-color: ${colors.white};
`;

const NavigationContent = ({
  selectedLabel,
  organization,
}: {
  selectedLabel: string;
  organization: OrganizationModel;
}) => {
  const {navigator, params} = useNavigation();
  const navigationItems = CompanyDetailsNavigation.getItems(organization);
  const selectedRoute = NavigationItems.getRouteForLabel({
    navigationItems,
    label: selectedLabel,
  });

  return (
    <TreeNavigation
      navigationItems={navigationItems}
      values={{route: selectedRoute}}
      handleSetValues={({route}: {route: string}) => {
        navigator.navigate(route, params);
      }}
    />
  );
};

interface CompanyDetailsPageContentProps {
  children: React.ReactNode;
  organization: OrganizationModel;
  selectedLabel: string;
  pageTitle: string;
  pageDescription?: string;
  actionElement?: React.ReactNode;
  pageContainerStyle?: React.CSSProperties;
  contentContainerStyle?: React.CSSProperties;
}

const CompanyDetailsPageContent = ({
  children,
  organization,
  selectedLabel,
  pageTitle,
  pageDescription,
  actionElement,
  pageContainerStyle,
  contentContainerStyle,
}: CompanyDetailsPageContentProps) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigation();
  const handleBackNavigation = () => {
    if (responsive.desktop) {
      navigator.navigate('CompaniesListPage');
    } else {
      navigator.navigate('CompanyDetailsPageMobileNavigation', params);
    }
  };
  return (
    <AdminAppPage
      title={organization?.name}
      containerStyle={pageContainerStyle}
      previousTitle={responsive.desktop ? 'Companies' : 'Settings'}
      handlePrevious={handleBackNavigation}
    >
      <ContentContainer>
        {responsive.desktop && (
          <React.Fragment>
            <NavigationContainer>
              <NavigationContent selectedLabel={selectedLabel} organization={organization} />
            </NavigationContainer>
            <VerticalDivider />
          </React.Fragment>
        )}
        <ContentBodyContainer style={contentContainerStyle}>
          <ScrollView
            contentContainerStyle={{
              alignSelf: 'center',
              width: '100%',
              // The extra maxWidth comes from the horizontal padding.
              maxWidth: 1200 + 2 * (responsive.desktop ? 24 : 0),
              paddingHorizontal: responsive.desktop ? 24 : 0,
              paddingVertical: responsive.desktop ? 24 : 16,
            }}
          >
            <CompanyPageContentHeader
              pageTitle={pageTitle}
              pageDescription={pageDescription}
              actionElement={actionElement}
            />
            <Space height={24} />
            {children}
          </ScrollView>
        </ContentBodyContainer>
      </ContentContainer>
    </AdminAppPage>
  );
};

interface CompanyDetailsPageProps {
  children: React.ReactNode;
  selectedLabel: string;
  pageTitle: string;
  pageDescription?: string;
  actionElement?: React.ReactNode;
  pageContainerStyle?: React.CSSProperties;
  contentContainerStyle?: React.CSSProperties;
}

const CompanyDetailsPage = ({
  children,
  selectedLabel,
  pageTitle,
  pageDescription,
  actionElement,
  pageContainerStyle,
  contentContainerStyle,
}: CompanyDetailsPageProps) => {
  const {params} = useNavigation();
  const {loading, data} = useQuery(CompanyDetailsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });

  if (loading && !data) {
    return <AdminAppPage title={'Companies / ...'} containerStyle={pageContainerStyle} isLoading />;
  }

  return (
    <CompanyDetailsPageContent
      organization={data.organization}
      selectedLabel={selectedLabel}
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      actionElement={actionElement}
      pageContainerStyle={pageContainerStyle}
      contentContainerStyle={contentContainerStyle}
    >
      {children}
    </CompanyDetailsPageContent>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyDetailsPage.query = gql`
  ${CompanyDetailsNavigation.getItems.fragment}

  query CompanyDetailsPage($slug: String!) {
    ${gql.query}
    organization(slug: $slug) {
      id
      name
      ...CompanyDetailsNavigation_getItems
    }
  }
`;

export default CompanyDetailsPage;
