// Libraries
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {generated, gqlTyped as gql, useTypedQuery as useQuery} from '@supermove/graphql';
import {
  useDrawer,
  useEffect,
  useNavigation,
  useResponsive,
  useState,
  useUrlFilters,
} from '@supermove/hooks';

// App
import EmptyState from '@shared/design/components/EmptyState';
import DashboardStatus from '@shared/modules/GlobalDashboard/enums/DashboardStatus';
import {GlobalDashboardSortKind} from '@shared/modules/GlobalDashboard/enums/GlobalDashboardSortKind';
import AdminAppPage from 'modules/App/components/AdminAppPage';
import CreateGlobalDashboardDrawer from 'modules/Dashboards/components/CreateGlobalDashboardDrawer';
import GlobalDashboardCard from 'modules/Dashboards/components/GlobalDashboardCard';
import GlobalDashboardListHeader from 'modules/Dashboards/components/GlobalDashboardListHeader';
import GlobalDashboardsPageHeader from 'modules/Dashboards/components/GlobalDashboardsPageHeader';
import GlobalDashboardsPageNavigation from 'modules/Dashboards/components/GlobalDashboardsPageNavigation';
import ManageDashboardCategoriesDrawer from 'modules/Dashboards/components/ManageDashboardCategoriesDrawer';
import ManageDashboardTagsDrawer from 'modules/Dashboards/components/ManageDashboardTagsDrawer';
import {GlobalDashboardsPageFiltersType} from 'modules/Dashboards/components/types';

const PAGE_TITLE = 'Reports';

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  height: 100%;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmptyStateContainer = Styled.View`
  height: 200px;
  justify-content: center;
  align-items: center;
`;

type GlobalDashboard = NonNullable<
  NonNullable<generated.GlobalDashboardsPageQuery['filteredGlobalDashboards']>[number]
>;

const NoResults = () => {
  return (
    <EmptyStateContainer>
      <EmptyState title={'No results.'} message={'Clear your search and try again.'} />
    </EmptyStateContainer>
  );
};

const GlobalDashboardsPage = () => {
  const {navigator} = useNavigation();
  const responsive = useResponsive();
  const numberOfColumns = responsive.desktop ? 2 : 1;

  const createGlobalDashboardDrawer = useDrawer({name: 'Create Global Dashboard Drawer'});
  const manageCategoriesDrawer = useDrawer({name: 'Manage Dashboard Categories Drawer'});
  const manageTagsDrawer = useDrawer({name: 'Manage Dashboard Tags Drawer'});

  const urlFilters = useUrlFilters<GlobalDashboardsPageFiltersType>({
    getRoute: () => '/reports',
    filterKeys: ['status', 'searchQuery', 'categoryIds', 'tagIds', 'onlyDefault', 'sort'],
    navigationLib: 'react-navigation',
  });

  useEffect(() => {
    const initialFilters = urlFilters.getFilters();
    if (!initialFilters.status || !initialFilters.sort) {
      urlFilters.handleUpdate({
        ...initialFilters,
        status: initialFilters.status ?? DashboardStatus.PUBLISHED,
        sort: initialFilters.sort ?? GlobalDashboardSortKind.ALPHABETICAL,
      });
    }
  }, [urlFilters]);

  const {loading, data, refetch} = useQuery(GlobalDashboardsPageQuery, {
    skip: !urlFilters.getFilters().status,
    fetchPolicy: 'cache-and-network',
    variables: {
      pagination: {page: 1, resultsPerPage: 20},
      ...urlFilters.getFilters(),
    },
  });
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState<boolean>(false);

  const globalDashboards = data?.filteredGlobalDashboards || [];

  const countsPerStatus = {
    [DashboardStatus.PUBLISHED]: data?.publishedGlobalDashboardsCount || 0,
    [DashboardStatus.DRAFT]: data?.draftGlobalDashboardsCount || 0,
    [DashboardStatus.ARCHIVED]: data?.archivedGlobalDashboardsCount || 0,
  };
  if (urlFilters.getFilters().status && !loading) {
    countsPerStatus[urlFilters.getFilters().status] = globalDashboards.length;
  }

  const handleView = async ({globalDashboardUuid}: {globalDashboardUuid: string}) => {
    const clearedFilters = (
      Object.keys(urlFilters.getFilters()) as (keyof GlobalDashboardsPageFiltersType)[]
    ).reduce((acc, key) => {
      acc[key] = undefined;
      return acc;
    }, {} as Partial<GlobalDashboardsPageFiltersType>);

    navigator.navigate('GlobalDashboardDetailsPage', {
      globalDashboardUuid,
      ...clearedFilters,
    });
  };

  return (
    <AdminAppPage
      HeaderElement={
        <GlobalDashboardsPageHeader
          urlFilters={urlFilters}
          title={PAGE_TITLE}
          isMobileSearchVisible={isMobileSearchVisible}
          setIsMobileSearchVisible={setIsMobileSearchVisible}
          handleCreate={createGlobalDashboardDrawer.handleOpen}
          handleManageCategories={manageCategoriesDrawer.handleOpen}
          handleManageTags={manageTagsDrawer.handleOpen}
        />
      }
    >
      <Row
        style={{
          flex: 1,
          alignItems: responsive.desktop ? 'flex-start' : 'center',
          flexDirection: responsive.desktop ? 'row' : 'column',
        }}
      >
        <GlobalDashboardsPageNavigation urlFilters={urlFilters} countsByStatus={countsPerStatus} />
        <Container>
          <FlatList
            listKey={'global-dashboards-page'}
            key={numberOfColumns}
            // @ts-expect-error the data has null, which is not supported
            data={globalDashboards}
            ListHeaderComponent={() => (
              <GlobalDashboardListHeader
                dashboardStatus={urlFilters.get('status')}
                urlFilters={urlFilters}
              />
            )}
            ItemSeparatorComponent={() => <Space height={16} />}
            numColumns={numberOfColumns}
            keyExtractor={(globalDashboard: GlobalDashboard) => globalDashboard.id}
            renderItem={({item}: {item: GlobalDashboard}) => (
              <GlobalDashboardCard
                key={item.id}
                globalDashboard={item}
                responsive={responsive}
                style={numberOfColumns > 1 ? {width: 'calc(50% - 8px)'} : {width: '100%'}}
                refetch={refetch}
                handleView={handleView}
              />
            )}
            ListEmptyComponent={NoResults}
            {...(numberOfColumns > 1
              ? {columnWrapperStyle: {justifyContent: 'space-between'}}
              : null)}
            contentContainerStyle={
              responsive.desktop
                ? {
                    maxWidth: 1200,
                    width: '100%',
                    paddingHorizontal: 24,
                    flex: 1,
                    alignSelf: 'center',
                  }
                : {}
            }
          />
        </Container>
      </Row>
      <CreateGlobalDashboardDrawer
        key={createGlobalDashboardDrawer.key}
        isOpen={createGlobalDashboardDrawer.isOpen}
        handleClose={createGlobalDashboardDrawer.handleClose}
        handleView={handleView}
        refetch={refetch}
      />
      <ManageDashboardCategoriesDrawer
        key={manageCategoriesDrawer.key}
        isOpen={manageCategoriesDrawer.isOpen}
        handleClose={manageCategoriesDrawer.handleClose}
        refetch={refetch}
      />
      <ManageDashboardTagsDrawer
        key={manageTagsDrawer.key}
        isOpen={manageTagsDrawer.isOpen}
        handleClose={manageTagsDrawer.handleClose}
        refetch={refetch}
      />
    </AdminAppPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const GlobalDashboardsPageQuery = gql(`
  query GlobalDashboardsPage(
        $sort: String!
        $status: String
        $categoryIds: [String!]
        $tagIds: [String!]
        $searchQuery: String
        $onlyDefault: Boolean
    ) {
    viewer {
      id
    }
    filteredGlobalDashboards(
      sort: $sort, 
      status: $status, 
      categoryIds: $categoryIds, 
      tagIds: $tagIds, 
      searchQuery: $searchQuery
      onlyDefault: $onlyDefault
    ) {
      id
      ...GlobalDashboardCard
    }
    publishedGlobalDashboardsCount: filteredGlobalDashboardsCount(
      status: "PUBLISHED",
    )
    draftGlobalDashboardsCount: filteredGlobalDashboardsCount(
      status: "DRAFT",
    )
    archivedGlobalDashboardsCount: filteredGlobalDashboardsCount(
      status: "ARCHIVED",
    )
  }
`);

export default GlobalDashboardsPage;
