// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useResponsive} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import Line from '@shared/design/components/Line';
import {CompanyFormProps} from '@shared/modules/Company/forms/CompanyForm';
import {SupermoveProductFormProps} from '@shared/modules/SupermoveProduct/forms/SupermoveProductForm';

const StepHeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const StepHeaderDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const OfficeProductFields = ({
  form,
  field,
  supermoveProductFormField,
  activePrimaryOrganizationsForCore,
}: {
  form:
    | Form<{companyForm: CompanyFormProps}>
    | Form<{supermoveProductForm: SupermoveProductFormProps}>;
  field: string;
  supermoveProductFormField: string;
  activePrimaryOrganizationsForCore: OrganizationModel[];
}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        index={0}
        name={`${supermoveProductFormField}.contractedTruckQuantity`}
        label={'Number of Trucks'}
        isRequired
        isResponsive
        input={{
          placeholder: 'Enter number of trucks',
        }}
        handleBlur={(event) => {
          const text = event.target.value;
          form.setFieldValue(
            `${supermoveProductFormField}.contractedTruckQuantity`,
            _.parseInt(text) || '',
          );
        }}
      />
      <Space height={16} />
      <Line />
      <Space height={16} />
      <StepHeaderText responsive={responsive}>Select Existing Company as Template</StepHeaderText>
      <Space height={8} />
      <StepHeaderDescription
        responsive={responsive}
      >{`The following modules will be copied over for Core: project types and job types, billing libraries, automations, document templates, email templates, SMS templates, reports, inventory templates, task templates.`}</StepHeaderDescription>
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        name={`${field}.sourceOrganizationId`}
        label={'Company'}
        component={DropdownInput}
        isResponsive
        input={{
          isClearable: true,
          setFieldValue: form.setFieldValue,
          placeholder: 'Select company',
          options: _.sortBy(activePrimaryOrganizationsForCore, ({name}) => _.toLower(name)).map(
            ({name, id, slug}) => ({
              label: name,
              value: id,
              slug,
            }),
          ),
          style: {flex: 1},
          onChangeValue: (newValue: string, option: {label: string}) => {
            if (option) {
              // The name is used for display purposes only on the review step
              form.setFieldValue(`${field}.sourceOrganizationName`, option.label);
            }
          },
          additionalSearchKeys: ['slug'],
        }}
        style={{flex: 1}}
      />
    </React.Fragment>
  );
};

// ------------------------------
// Data
// ------------------------------
OfficeProductFields.fragment = gql`
  fragment OfficeProductFields on Organization {
    id
    name
    slug
  }
`;

export default OfficeProductFields;
