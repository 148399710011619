// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {UrlFiltersType, PaginationType} from '@supermove/hooks';
import {Company, CompanyModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Line from '@shared/design/components/Line';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import TreeNavigation from '@shared/design/components/TreeNavigation';
import CompaniesGroupTabs from 'modules/Company/List/components/CompaniesGroupTabs';
import CompaniesSearchAndFilters from 'modules/Company/List/components/CompaniesSearchAndFilters';
import CompaniesTable from 'modules/Company/List/components/CompaniesTable';
import {CompanyListFilterType} from 'modules/Company/List/types/CompanyListUrlFilterType';

const ContentContainer = Styled.View`
  flex-direction: row;
  flex: 1;
`;

const TreeNavigationContainer = Styled.View`
  background-color: ${colors.white};
`;

const RightContentContainer = Styled.View`
  flex: 1;
`;

const SearchAndFiltersContainer = Styled.View`
  padding-horizontal: 24px;
`;

const CompaniesPageContentDesktop = ({
  urlFilters,
  companies,
  isLoading,
  handleUpdateGroupBy,
  handleUpdateGroup,
  pagination,
  refetch,
}: {
  urlFilters: UrlFiltersType<CompanyListFilterType>;
  companies: CompanyModel[];
  isLoading: boolean;
  handleUpdateGroupBy: (groupBy?: string) => void;
  handleUpdateGroup: (group?: string) => void;
  pagination: PaginationType;
  refetch: () => void;
}) => {
  return (
    <ContentContainer>
      <TreeNavigationContainer>
        <Space height={12} />
        <TreeNavigation
          width={200}
          navigationItems={Company.GROUP_BY_DEFINITIONS.map(({label, value}) => ({
            label,
            value,
            valueKey: 'groupBy',
          }))}
          values={{groupBy: urlFilters.get('groupBy') || Company.GROUP_BY_DEFINITIONS[0].value}}
          handleSetValues={({groupBy}: {groupBy: string}) => handleUpdateGroupBy(groupBy)}
        />
      </TreeNavigationContainer>
      <Line isVertical />
      <RightContentContainer>
        <Space height={24} />
        <SearchAndFiltersContainer>
          <CompaniesSearchAndFilters urlFilters={urlFilters} />
          <Space height={24} />
          <CompaniesGroupTabs urlFilters={urlFilters} handleUpdateGroup={handleUpdateGroup} />
        </SearchAndFiltersContainer>
        <CompaniesTable companies={companies} isLoading={isLoading} refetch={refetch} />
        <Space height={24} />
        <PaginationBar pagination={pagination} />
        <Space height={24} />
      </RightContentContainer>
    </ContentContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompaniesPageContentDesktop.fragment = gql`
  ${CompaniesTable.fragment}

  fragment CompaniesPageContentDesktop on Company {
    id
    ...CompaniesTable
  }
`;

export default CompaniesPageContentDesktop;
