// Libraries
import React from 'react';

// Supermove
import {PhoneInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import UpdatePrimaryPhoneForwardingPhoneNumberForm from '@shared/modules/Settings/forms/UpdatePrimaryPhoneForwardingPhoneNumberForm';
import useUpdatePrimaryPhoneForwardingPhoneNumberMutation from '@shared/modules/Settings/hooks/useUpdatePrimaryPhoneForwardingPhoneNumberMutation';

interface UpdatePrimaryPhoneForwardingPhoneNumberDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  forwardingPhoneNumber: string;
  organizationId: string;
  refetch: () => void;
}

const UpdatePrimaryPhoneForwardingPhoneNumberDrawer = ({
  isOpen,
  handleClose,
  forwardingPhoneNumber,
  organizationId,
  refetch,
}: UpdatePrimaryPhoneForwardingPhoneNumberDrawerProps) => {
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Forwarding phone number set.',
  });

  const updatePrimaryPhoneForwardingPhoneNumberForm =
    UpdatePrimaryPhoneForwardingPhoneNumberForm.edit({
      forwardingPhoneNumber,
      organizationId,
    });

  const {form, submitting, handleSubmit} = useUpdatePrimaryPhoneForwardingPhoneNumberMutation({
    updatePrimaryPhoneForwardingPhoneNumberForm,
    onSuccess: () => {
      successToast.handleToast();
      refetch();
      handleClose();
    },
    onError: (errors: unknown) => console.log({errors}),
  });

  const field = 'updatePrimaryPhoneForwardingPhoneNumberForm';

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Update Forwarding Phone Number'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <FieldInput
        {...form}
        index={0}
        label={'Forwarding Phone Number'}
        name={`${field}.forwardingPhoneNumber`}
        component={PhoneInput}
        isResponsive
        input={{
          setFieldValue: form.setFieldValue,
          placeholder: 'Enter phone number',
        }}
      />
    </DrawerWithAction>
  );
};

UpdatePrimaryPhoneForwardingPhoneNumberDrawer.fragment = gql`
  ${UpdatePrimaryPhoneForwardingPhoneNumberForm.edit.fragment}

  fragment UpdatePrimaryPhoneForwardingPhoneNumberDrawer on Settings {
    id
    ...UpdatePrimaryPhoneForwardingPhoneNumberForm_edit
  }
`;

export default UpdatePrimaryPhoneForwardingPhoneNumberDrawer;
