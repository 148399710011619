// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {Form} from '@supermove/hooks';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import CompanyKind from '@shared/modules/Company/enums/CompanyKind';
import {CompanyFormProps} from '@shared/modules/Company/forms/CompanyForm';

const CompanyDetailsFields = ({
  form,
  field,
}: {
  form: Form<{companyForm: CompanyFormProps}>;
  field: string;
}) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        name={`${field}.name`}
        label={'Company Name'}
        isRequired
        isResponsive
        input={{
          placeholder: 'Enter company name',
        }}
        handleBlur={(event) => {
          if (_.get(form.values, `${field}.identifier`) === '') {
            const text = event.target.value;
            // Automatically generate an identifier based on the company name
            // Lowercase, replace spaces with -, and remove any non-alphanumeric characters
            form.setFieldValue(`${field}.identifier`, text.toLowerCase().replace(/[^\w-]/g, ''));
          }
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        name={`${field}.identifier`}
        label={'Identifier'}
        isRequired
        isResponsive
        input={{
          placeholder: 'Enter identifier',
        }}
        tooltip={`Requirements:
  • Must be unique
  • Must contain at least 3 characters
  • Only letters, numbers, -, and _ are allowed
  • Cannot contain spaces`}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        label={'Company Type'}
        name={`${field}.kind`}
        component={DropdownInput}
        input={{
          options: CompanyKind.getDropdownOptions(),
          setFieldValue: form.setFieldValue,
          placeholder: 'Select type',
          style: {width: '100%'},
          isPortaled: true,
        }}
        isRequired
        isResponsive
      />
      <Space height={16} />
      <FieldInput
        {...form}
        name={`${field}.salesforceId`}
        label={'Salesforce ID'}
        isResponsive
        isRequired={_.get(form.values, `${field}.kind`) === CompanyKind.CUSTOMER}
        input={{
          placeholder: 'Enter salesforce ID',
        }}
        tooltip={'This is the 18 digit ID that can be found on Vitally.'}
      />
    </React.Fragment>
  );
};

export default CompanyDetailsFields;
