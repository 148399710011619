// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery, useModal, useDrawer} from '@supermove/hooks';
import {OrganizationModel, SupermoveProductModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Datetime, List} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import Table from '@shared/design/components/Table';
import TextTooltip from '@shared/design/components/TextTooltip';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';
import AddCoreAppsToCompanyModal from 'modules/Company/CompanyDetails/components/AddCoreAppsToCompanyModal';
import AddSalesAppToCompanyModal from 'modules/Company/CompanyDetails/components/AddSalesAppToCompanyModal';
import UpdateSupermoveProductSettingsDrawer from 'modules/Company/CompanyDetails/components/UpdateSupermoveProductSettingsDrawer';

const TooltipContainer = Styled.View``;

const getActiveSupermoveProductAction = ({
  supermoveProduct,
  refetch,
}: {
  supermoveProduct: SupermoveProductModel;
  refetch: () => void;
}) => {
  return {
    text: 'Edit deployed date',
    onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
    actionHook: {
      hook: useDrawer,
      hookArgument: {
        name: `Update Supermove Product Settings: ${SupermoveProductKind.getLabel(supermoveProduct.kind)}`,
      },
      renderComponent: ({
        isOpen,
        handleClose,
        hookKey,
      }: {
        isOpen: boolean;
        handleClose: () => void;
        hookKey: string;
      }) => {
        return (
          <UpdateSupermoveProductSettingsDrawer
            key={hookKey}
            isOpen={isOpen}
            handleClose={handleClose}
            refetch={refetch}
            supermoveProduct={supermoveProduct}
          />
        );
      },
    },
  };
};

const getInactiveOfficeAction = ({
  refetch,
  organization,
}: {
  refetch: () => void;
  organization: OrganizationModel;
}) => {
  return {
    text: 'Add product',
    onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
    actionHook: {
      hook: useModal,
      hookArgument: {
        name: 'Add Core Apps To Company Modal',
      },
      renderComponent: ({
        isOpen,
        handleClose,
        hookKey,
      }: {
        isOpen: boolean;
        handleClose: () => void;
        hookKey: string;
      }) => {
        return (
          <AddCoreAppsToCompanyModal
            key={hookKey}
            isOpen={isOpen}
            handleClose={handleClose}
            refetch={refetch}
            companyId={organization.company.id}
          />
        );
      },
    },
  };
};

const getInactiveSalesAction = ({
  refetch,
  organization,
}: {
  refetch: () => void;
  organization: OrganizationModel;
}) => {
  return {
    text: 'Add product',
    onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
    actionHook: {
      hook: useModal,
      hookArgument: {
        name: 'Add Sales App To Company Modal',
      },
      renderComponent: ({
        isOpen,
        handleClose,
        hookKey,
      }: {
        isOpen: boolean;
        handleClose: () => void;
        hookKey: string;
      }) => {
        return (
          <AddSalesAppToCompanyModal
            key={hookKey}
            isOpen={isOpen}
            handleClose={handleClose}
            refetch={refetch}
            organization={organization}
          />
        );
      },
    },
  };
};

const getColumnActions = ({
  supermoveProduct,
  refetch,
  organization,
}: {
  supermoveProduct: SupermoveProductModel;
  refetch: () => void;
  organization: OrganizationModel;
}) => {
  const {kind, status} = supermoveProduct;
  return [
    ...List.insertIf(
      status === SupermoveProductStatus.ACTIVE,
      getActiveSupermoveProductAction({supermoveProduct, refetch}),
    ),
    ...List.insertIf(
      kind === SupermoveProductKind.OFFICE && status === SupermoveProductStatus.INACTIVE,
      getInactiveOfficeAction({refetch, organization}),
    ),
    ...List.insertIf(
      kind === SupermoveProductKind.SALES && status === SupermoveProductStatus.INACTIVE,
      getInactiveSalesAction({refetch, organization}),
    ),
  ];
};

const getColumnDefinitions = ({
  organization,
  refetch,
}: {
  organization: OrganizationModel;
  refetch: () => void;
}) => {
  return [
    {
      headerLabel: 'App',
      flex: 1,
      cellText: (supermoveProduct: SupermoveProductModel) =>
        SupermoveProductKind.getLabel(supermoveProduct.kind),
    },
    {
      headerLabel: 'Status',
      flex: 1,
      cellComponent: (supermoveProduct: SupermoveProductModel) => (
        <Badge
          label={SupermoveProductStatus.getDisplay(supermoveProduct.status)}
          color={
            supermoveProduct.status === SupermoveProductStatus.ACTIVE
              ? colors.green.status
              : colors.gray.secondary
          }
          isResponsive
        />
      ),
    },
    {
      headerLabel: 'Deployed Date',
      flex: 1,
      cellText: (supermoveProduct: SupermoveProductModel) =>
        supermoveProduct.deployedDate
          ? Datetime.convertToDisplayDate(
              supermoveProduct.deployedDate,
              Datetime.DISPLAY_SHORT_DATE,
            )
          : null,
    },
    {
      headerLabel: 'Actions',
      width: 80,
      cellStyle: {alignItems: 'center'},
      cellSlotComponent: (supermoveProduct: SupermoveProductModel) => {
        if (
          supermoveProduct.kind === SupermoveProductKind.CREW &&
          supermoveProduct.status === SupermoveProductStatus.INACTIVE
        ) {
          return (
            <TextTooltip
              text={
                supermoveProduct.status === SupermoveProductStatus.INACTIVE
                  ? 'Crew App and Office App will be activated together.'
                  : 'Crew App and Office App are edited together.'
              }
            >
              <TooltipContainer>
                <Icon source={Icon.CircleInfo} size={16} color={colors.gray.secondary} />
              </TooltipContainer>
            </TextTooltip>
          );
        }
        return null;
      },
      actions: (supermoveProduct: SupermoveProductModel) =>
        getColumnActions({supermoveProduct, refetch, organization}),
    },
  ];
};

const CompanyDetailsProductsPageContent = ({
  organization,
  refetch,
}: {
  organization: OrganizationModel;
  refetch: () => void;
}) => {
  const {navigator, params} = useNavigation();
  const supermoveProducts = getSupermoveProductItems(
    organization.officeAppProduct,
    organization.crewAppProduct,
    organization.salesAppProduct,
  ) as SupermoveProductItem[];

  return (
    <Table
      items={supermoveProducts}
      itemKey={'id'}
      columnDefinitions={getColumnDefinitions({organization, refetch})}
      hasBorder
      onRowPress={(supermoveProduct: SupermoveProductModel) => {
        switch (supermoveProduct.kind) {
          case SupermoveProductKind.SALES:
            navigator.navigate('CompanyDetailsAiSalesCopilotGeneralPage', {slug: params.slug});
            break;
          case SupermoveProductKind.CREW:
          case SupermoveProductKind.OFFICE:
            navigator.navigate('CompanyDetailsOfficeAppGeneralPage', {slug: params.slug});
            break;
          default:
            return;
        }
      }}
    />
  );
};

const createDefaultSupermoveProductItem = (kind: string) => ({
  id: `default-${kind}`,
  kind,
  status: SupermoveProductStatus.INACTIVE,
  deployedDate: null,
});

const getSupermoveProductItems = (
  officeApp?: SupermoveProductModel,
  crewApp?: SupermoveProductModel,
  salesApp?: SupermoveProductModel,
) => {
  return [
    officeApp ?? createDefaultSupermoveProductItem(SupermoveProductKind.OFFICE),
    crewApp ?? createDefaultSupermoveProductItem(SupermoveProductKind.CREW),
    salesApp ?? createDefaultSupermoveProductItem(SupermoveProductKind.SALES),
  ];
};

// Needed to fix compatibility with the TableBuilder ItemType
interface SupermoveProductItem extends SupermoveProductModel {
  [key: string]: unknown;
}

const CompanyDetailsProductsPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(CompanyDetailsProductsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });

  return (
    <CompanyDetailsPage
      selectedLabel={'company/products'}
      pageTitle={'Products'}
      pageDescription={'Add and configure Supermove products for this company.'}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          const {organization} = data;
          return (
            <CompanyDetailsProductsPageContent organization={organization} refetch={refetch} />
          );
        }}
      </Loading>
    </CompanyDetailsPage>
  );
};

CompanyDetailsProductsPage.query = gql`
  ${UpdateSupermoveProductSettingsDrawer.fragment}

  query CompanyDetailsProductsPage($slug: String!) {
    ${gql.query}
    organization(slug: $slug) {
      id
      officeAppProduct: supermoveProduct(kind: "OFFICE") {
        id
        kind
        status
        deployedDate
        ...UpdateSupermoveProductSettingsDrawer
      }
      crewAppProduct: supermoveProduct(kind: "CREW") {
        id
        kind
        status
        deployedDate
        ...UpdateSupermoveProductSettingsDrawer
      }
      salesAppProduct: supermoveProduct(kind: "SALES") {
        id
        kind
        status
        deployedDate
        ...UpdateSupermoveProductSettingsDrawer
      }
      company {
        id
      }
    }
  }
`;

export default CompanyDetailsProductsPage;
