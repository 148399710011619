// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form} from '@supermove/hooks';
import {CompanyModel} from '@supermove/models';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import CompanyKind from '@shared/modules/Company/enums/CompanyKind';
import {CompanyDetailsProfileFormToFormType} from '@shared/modules/Company/forms/CompanyDetailsProfileForm';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const CompanyDetailsProfilePanelEdit = ({
  form,
  company,
}: {
  form: Form<{companyDetailsProfileForm: CompanyDetailsProfileFormToFormType}>;
  company: CompanyModel;
}) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <FieldInput
            {...form}
            label={'Name'}
            name={'companyDetailsProfileForm.name'}
            isRequired
            isResponsive
          />
          <Space height={20} />
          <FieldInput
            {...form}
            label={'Company Type'}
            name={'companyDetailsProfileForm.kind'}
            component={DropdownInput}
            input={{
              options: CompanyKind.getDropdownOptions(),
              setFieldValue: form.setFieldValue,
              placeholder: 'Select type',
              style: {width: '100%'},
            }}
            isRequired
            isResponsive
          />
        </Column>
        <Space width={20} />
        <Column>
          <FieldInput
            {...form}
            label={'Identifier'}
            input={{
              disabled: true,
              value: company.slug,
            }}
            isResponsive
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyDetailsProfilePanelEdit.fragment = gql`
  fragment CompanyDetailsProfilePanelEdit on Company {
    id
    slug
  }
`;

export default CompanyDetailsProfilePanelEdit;
