// Supermove
import {createStackNavigator, createSwitchWrapperNavigator} from '@supermove/navigation';

// App
import Root from 'modules/App/Root';
import AuthenticatedWrapper from 'modules/App/components/AuthenticatedWrapper';
import RouterWrapper from 'modules/App/components/RouterWrapper';
import LoginPage from 'modules/Authentication/Login/LoginPage';
import CompaniesListPage from 'modules/Company/CompaniesListPage';
import CompanyDetailsAiSalesCopilotGeneralPage from 'modules/Company/CompanyDetails/CompanyDetailsAiSalesCopilotGeneralPage';
import CompanyDetailsBranchesPage from 'modules/Company/CompanyDetails/CompanyDetailsBranchesPage';
import CompanyDetailsOfficeAppGeneralPage from 'modules/Company/CompanyDetails/CompanyDetailsOfficeAppGeneralPage';
import CompanyDetailsOfficePhoneNumbersPage from 'modules/Company/CompanyDetails/CompanyDetailsOfficePhoneNumbersPage';
import CompanyDetailsOverviewPage from 'modules/Company/CompanyDetails/CompanyDetailsOverviewPage';
import CompanyDetailsPageMobileNavigation from 'modules/Company/CompanyDetails/CompanyDetailsPageMobileNavigation';
import CompanyDetailsProductsPage from 'modules/Company/CompanyDetails/CompanyDetailsProductsPage';
import CompanyRootPage from 'modules/Company/CompanyRootPage';
import CompanyModulesCalendarsPage from 'modules/Company/Modules/CompanyModulesCalendarsPage';
import CompanyModulesCommunicationPage from 'modules/Company/Modules/CompanyModulesCommunicationPage';
import CompanyModulesEquipmentAndMaterialsPage from 'modules/Company/Modules/CompanyModulesEquipmentAndMaterialsPage';
import CompanyModulesInventoryPage from 'modules/Company/Modules/CompanyModulesInventoryPage';
import CompanyModulesPaymentsPage from 'modules/Company/Modules/CompanyModulesPaymentsPage';
import CompanyModulesProjectsPage from 'modules/Company/Modules/CompanyModulesProjectsPage';
import CompanyModulesStoragePage from 'modules/Company/Modules/CompanyModulesStoragePage';
import CompanyModulesTimesheetsPage from 'modules/Company/Modules/CompanyModulesTimesheetsPage';
import GlobalDashboardDetailsPage from 'modules/Dashboards/DashboardDetails/GlobalDashboardDetailsPage';
import GlobalDashboardsPage from 'modules/Dashboards/GlobalDashboardsPage';
import AITestingPage from 'modules/Internal/AI/AITestingPage';
import LeadProviderPage from 'modules/LeadProvider/LeadProviderPage';
import SuperAdminsListPage from 'modules/Users/SuperAdmin/SuperAdminsListPage';

const Authentication = createStackNavigator(
  {
    LoginPage: {
      path: '',
      screen: LoginPage,
    },
  },
  {
    initialRouteName: 'LoginPage',
    headerMode: 'none',
    backBehavior: 'history',
  },
);

const CompanyPages = {
  CompaniesListPage: {
    path: 'companies',
    screen: CompaniesListPage,
  },
  CompanyRootPage: {
    path: 'companies/:slug',
    screen: CompanyRootPage,
  },
  CompanyDetailsPageMobileNavigation: {
    path: 'companies/:slug/settings',
    screen: CompanyDetailsPageMobileNavigation,
  },

  // Company Settings Pages
  CompanyDetailsOverviewPage: {
    path: 'companies/:slug/company/overview',
    screen: CompanyDetailsOverviewPage,
  },
  CompanyDetailsProductsPage: {
    path: 'companies/:slug/company/products',
    screen: CompanyDetailsProductsPage,
  },
  CompanyDetailsBranchesPage: {
    path: 'companies/:slug/company/branches',
    screen: CompanyDetailsBranchesPage,
  },

  // Company Modules Pages
  CompanyModulesCalendarsPage: {
    path: 'companies/:slug/modules/calendars',
    screen: CompanyModulesCalendarsPage,
  },
  CompanyModulesCommunicationPage: {
    path: 'companies/:slug/modules/communication',
    screen: CompanyModulesCommunicationPage,
  },
  CompanyModulesEquipmentAndMaterialsPage: {
    path: 'companies/:slug/modules/equipment-and-materials',
    screen: CompanyModulesEquipmentAndMaterialsPage,
  },
  CompanyModulesInventoryPage: {
    path: 'companies/:slug/modules/inventory',
    screen: CompanyModulesInventoryPage,
  },
  CompanyModulesPaymentsPage: {
    path: 'companies/:slug/modules/payments',
    screen: CompanyModulesPaymentsPage,
  },
  CompanyModulesProjectsPage: {
    path: 'companies/:slug/modules/projects',
    screen: CompanyModulesProjectsPage,
  },
  CompanyModulesStoragePage: {
    path: 'companies/:slug/modules/storage',
    screen: CompanyModulesStoragePage,
  },
  CompanyModulesTimesheetsPage: {
    path: 'companies/:slug/modules/timesheets',
    screen: CompanyModulesTimesheetsPage,
  },

  // Company Office App Pages
  CompanyDetailsOfficeAppGeneralPage: {
    path: 'companies/:slug/office/general',
    screen: CompanyDetailsOfficeAppGeneralPage,
  },
  CompanyDetailsOfficePhoneNumbersPage: {
    path: 'companies/:slug/office/phone-numbers',
    screen: CompanyDetailsOfficePhoneNumbersPage,
  },

  // Company AI Sales Copilot Pages
  CompanyDetailsAiSalesCopilotGeneralPage: {
    path: 'companies/:slug/ai-sales-copilot/general',
    screen: CompanyDetailsAiSalesCopilotGeneralPage,
  },
};

const ReportPages = {
  GlobalDashboardsPage: {
    path: 'reports',
    screen: GlobalDashboardsPage,
  },
  GlobalDashboardDetailsPage: {
    path: 'reports/:globalDashboardUuid',
    screen: GlobalDashboardDetailsPage,
  },
};

const SuperUserPages = {
  SuperAdminsListPage: {
    path: 'users/super-admins',
    screen: SuperAdminsListPage,
  },
};

const AITestingPages = {
  AITestingPage: {
    path: 'ai',
    screen: AITestingPage,
  },
};

const LeadProviderPages = {
  LeadProviderPage: {
    path: 'lead-providers',
    screen: LeadProviderPage,
  },
};

const App = createStackNavigator(
  {
    Root: {
      path: '',
      screen: Root,
    },
    ...CompanyPages,
    ...ReportPages,
    ...SuperUserPages,
    ...AITestingPages,
    ...LeadProviderPages,
  },
  {
    initialRouteName: 'Root',
    headerMode: 'none',
    backBehavior: 'history',
  },
);

const Authenticated = createSwitchWrapperNavigator(
  {
    App: {
      path: '',
      screen: App,
    },
  },
  {
    initialRouteName: 'App',
    backBehavior: 'history',
    wrapper: AuthenticatedWrapper,
  },
);

// A SwitchWrapperNavigator is a SwitchNavigator with an extra component rendered
// which has access to the `navigation` prop.
const Router = createSwitchWrapperNavigator(
  {
    Authentication: {
      path: 'login',
      screen: Authentication,
    },
    Authenticated: {
      path: '',
      screen: Authenticated,
    },
  },
  {
    initialRouteName: 'Authenticated',
    backBehavior: 'history',
    wrapper: RouterWrapper,
  },
);

export default Router;
