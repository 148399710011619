// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';

interface CreateLeadProviderGlobalIntegrationMutation {
  createLeadProviderGlobalIntegrationForm: {
    name: string;
    description: string;
    url: string;
  };
  onSuccess: () => void;
  onError: (error: MutationError[]) => void;
}

const useCreateLeadProviderGlobalIntegrationMutation = ({
  createLeadProviderGlobalIntegrationForm,
  onSuccess,
  onError,
}: CreateLeadProviderGlobalIntegrationMutation) => {
  const form = useForm({
    initialValues: {
      createLeadProviderGlobalIntegrationForm,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateLeadProviderGlobalIntegrationMutation.mutation,
    variables: {
      createLeadProviderGlobalIntegrationForm: form.values.createLeadProviderGlobalIntegrationForm,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateLeadProviderGlobalIntegrationMutation.mutation = gql`
    mutation useCreateLeadProviderGlobalIntegrationMutation($createLeadProviderGlobalIntegrationForm: CreateLeadProviderGlobalIntegrationForm!) {
        response: createLeadProviderGlobalIntegration(createLeadProviderGlobalIntegrationForm: $createLeadProviderGlobalIntegrationForm) {
            ${gql.errors}
            leadProviderGlobalIntegration {
                id
            }
        }
    }
`;

export default useCreateLeadProviderGlobalIntegrationMutation;
