// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, Styled, Space} from '@supermove/components';
import {
  Form,
  useEffect,
  useForm,
  useDebouncedCallback,
  usePopover,
  useResponsive,
  UrlFiltersType,
  PopoverType,
} from '@supermove/hooks';
import {Company} from '@supermove/models';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import DateField from '@shared/design/components/Field/DateField';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import SearchBar from '@shared/design/components/SearchBar';
import PopoverFilterWithFooter from '@shared/modules/App/components/PopoverFilterWithFooter';
import CompanyKind from '@shared/modules/Company/enums/CompanyKind';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import {CompanyListFilterType} from 'modules/Company/List/types/CompanyListUrlFilterType';

const Row = Styled.View<{index?: number}>`
  flex-direction: row;
  z-index: ${({index}) => (index ? 100 - index : 0)};
`;

const FILTER_KEYS: (keyof CompanyListFilterType)[] = [
  'companyKinds',
  'organizationKinds',
  'productKinds',
  'numberOfLicensesMin',
  'numberOfLicensesMax',
  'numberOfTrucksMin',
  'numberOfTrucksMax',
  'createdAtMin',
  'createdAtMax',
];

type FormType = Form<{
  companyKinds: string[];
  organizationKinds: string[];
  productKinds: string[];
  numberOfLicensesMin: string;
  numberOfLicensesMax: string;
  numberOfTrucksMin: string;
  numberOfTrucksMax: string;
  createdAtMin: string;
  createdAtMax: string;
}>;

const getFormInitialValues = ({
  urlFilters,
}: {
  urlFilters: UrlFiltersType<CompanyListFilterType>;
}) => {
  return {
    companyKinds: (urlFilters.get('companyKinds') as string[] | undefined) || [],
    organizationKinds: (urlFilters.get('organizationKinds') as string[] | undefined) || [],
    productKinds: (urlFilters.get('productKinds') as string[] | undefined) || [],
    numberOfLicensesMin: urlFilters.get('numberOfLicensesMin') || '',
    numberOfLicensesMax: urlFilters.get('numberOfLicensesMax') || '',
    numberOfTrucksMin: urlFilters.get('numberOfTrucksMin') || '',
    numberOfTrucksMax: urlFilters.get('numberOfTrucksMax') || '',
    createdAtMin: urlFilters.get('createdAtMin') || '',
    createdAtMax: urlFilters.get('createdAtMax') || '',
  };
};

const handleClear = ({form}: {form: FormType}) => {
  form.setValues({
    companyKinds: [],
    organizationKinds: [],
    productKinds: [],
    numberOfLicensesMin: '',
    numberOfLicensesMax: '',
    numberOfTrucksMin: '',
    numberOfTrucksMax: '',
    createdAtMin: '',
    createdAtMax: '',
  });
};

const handleApply = ({
  form,
  urlFilters,
}: {
  form: FormType;
  urlFilters: UrlFiltersType<CompanyListFilterType>;
}) => {
  const updatedFilters = FILTER_KEYS.reduce((result, key) => {
    const value = _.get(form.values, key);
    return {...result, [key]: _.isEmpty(value) ? undefined : value};
  }, {});

  urlFilters.handleUpdate(updatedFilters);
};

const LicensesFields = ({form, index}: {form: FormType; index: number}) => {
  return (
    <Row index={index}>
      <FieldInput
        {...form}
        label={'Number of Licenses'}
        name={'numberOfLicensesMin'}
        input={{
          placeholder: 'Minimum',
        }}
        isResponsive
        style={{flex: 1}}
      />
      <Space width={16} />
      <FieldInput
        {...form}
        name={'numberOfLicensesMax'}
        label={''}
        input={{
          placeholder: 'Maximum',
        }}
        isResponsive
        style={{flex: 1}}
      />
    </Row>
  );
};

const TrucksFields = ({form, index}: {form: FormType; index: number}) => {
  return (
    <Row index={index}>
      <FieldInput
        {...form}
        label={'Number of Trucks'}
        name={'numberOfTrucksMin'}
        input={{
          placeholder: 'Minimum',
        }}
        isResponsive
        style={{flex: 1}}
      />
      <Space width={16} />
      <FieldInput
        {...form}
        name={'numberOfTrucksMax'}
        label={' '}
        input={{
          placeholder: 'Maximum',
        }}
        isResponsive
        style={{flex: 1}}
      />
    </Row>
  );
};

const CreatedAtFields = ({form, index}: {form: FormType; index: number}) => {
  return (
    <Row index={index}>
      <DateField form={form} label={'Created At'} field={'createdAtMin'} isFullWidth isClearable />
      <Space width={16} />
      <DateField
        form={form}
        label={' '}
        field={'createdAtMax'}
        isFullWidth
        position={'bottom-end'}
        isClearable
      />
    </Row>
  );
};

const CompaniesFilters = ({
  form,
  urlFilters,
}: {
  form: FormType;
  urlFilters: UrlFiltersType<CompanyListFilterType>;
}) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        label={'Company Types'}
        name={'companyKinds'}
        component={MultiDropdownCheckboxInput}
        input={{
          options: CompanyKind.getDropdownOptions(),
          setFieldValue: form.setFieldValue,
          usePills: true,
          isResponsive: true,
          disabled: urlFilters.get('groupBy') === Company.GROUP_BY.KIND,
        }}
        isResponsive
        index={0}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        label={'Branch Types'}
        name={'organizationKinds'}
        component={MultiDropdownCheckboxInput}
        input={{
          options: OrganizationKind.getFilterDropdownOptions(),
          setFieldValue: form.setFieldValue,
          onChangeValue: (selections: string[]) =>
            _.last(selections) === OrganizationKind.MAIN
              ? form.setFieldValue('organizationKinds', [OrganizationKind.MAIN])
              : form.setFieldValue(
                  'organizationKinds',
                  selections.filter((selection) => selection !== OrganizationKind.MAIN),
                ),
          usePills: true,
          isResponsive: true,
        }}
        isResponsive
        index={1}
      />
      <Space height={16} />
      <LicensesFields form={form} index={2} />
      <Space height={16} />
      <TrucksFields form={form} index={3} />
      <Space height={16} />
      <FieldInput
        {...form}
        label={'Products'}
        name={'productKinds'}
        component={MultiDropdownCheckboxInput}
        input={{
          options: SupermoveProductKind.getDropdownOptions(),
          setFieldValue: form.setFieldValue,
          usePills: true,
          isResponsive: true,
          disabled: urlFilters.get('groupBy') === Company.GROUP_BY.PRODUCT,
        }}
        isResponsive
        index={4}
      />
      <Space height={16} />
      <CreatedAtFields form={form} index={5} />
      <Space height={16} />
    </React.Fragment>
  );
};

const CompaniesFiltersButton = ({
  popover,
  urlFilters,
}: {
  popover: PopoverType;
  urlFilters: UrlFiltersType<CompanyListFilterType>;
}) => {
  const responsive = useResponsive();
  const activeFiltersCount = urlFilters.getFilterCount({
    filterKeys: FILTER_KEYS,
  });
  const form = useForm({
    initialValues: getFormInitialValues({urlFilters}),
    enableReinitialize: true,
  });

  // Reset the form whenever you reopen the popover
  useEffect(() => {
    if (popover.isOpen && form.dirty) {
      form.setValues(getFormInitialValues({urlFilters}));
    }
  }, [popover.isOpen]);

  return (
    <React.Fragment>
      <Popover.Content innerRef={popover.ref}>
        <SecondaryButton
          onPress={popover.handleToggle}
          text={
            responsive.desktop
              ? `Filters${activeFiltersCount ? ` (${activeFiltersCount})` : ''}`
              : `(${activeFiltersCount})`
          }
          iconLeft={Icon.Filter}
          isResponsive
        />
      </Popover.Content>
      <PopoverFilterWithFooter
        activeFiltersCount={urlFilters.getFilterCount({
          filterKeys: FILTER_KEYS,
        })}
        popover={popover}
        handleClear={() => handleClear({form})}
        handleApply={() => {
          popover.handleClose();
          handleApply({form, urlFilters});
        }}
      >
        <CompaniesFilters form={form} urlFilters={urlFilters} />
      </PopoverFilterWithFooter>
    </React.Fragment>
  );
};

const CompaniesSearchAndFilters = ({
  urlFilters,
}: {
  urlFilters: UrlFiltersType<CompanyListFilterType>;
}) => {
  const responsive = useResponsive();
  const handleChangeSearch = useDebouncedCallback(
    (input) => urlFilters.handleUpdate({searchQuery: input || undefined}),
    250,
    {leading: true},
  );
  const companyFiltersPopover = usePopover();

  return (
    <Row responsive={responsive}>
      <SearchBar
        onChangeText={handleChangeSearch}
        placeholder='Search'
        containerStyle={{flex: 1}}
        style={{flex: 1}}
        defaultValue={(urlFilters.get('searchQuery') as string | undefined) || ''}
        isClearable
        isResponsive
      />
      <Space width={16} />
      <CompaniesFiltersButton popover={companyFiltersPopover} urlFilters={urlFilters} />
    </Row>
  );
};

export default CompaniesSearchAndFilters;
