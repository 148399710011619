// Libraries
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {useQuery, useResponsive} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import FieldInput from '@shared/design/components/Field/FieldInput';
import useCreateLeadProviderGlobalIntegrationMutation from 'modules/LeadProvider/components/useCreateLeadProviderGlobalIntegrationMutation';

const Container = Styled.View``;

const CreateLeadProviderDrawer = ({
  isOpen,
  handleClose,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  refetch: ReturnType<typeof useQuery>['refetch'];
}) => {
  const responsive = useResponsive();
  const {form, submitting, handleSubmit} = useCreateLeadProviderGlobalIntegrationMutation({
    createLeadProviderGlobalIntegrationForm: {
      name: '',
      description: '',
      url: '',
    },
    onSuccess: () => {
      form.resetForm();
      handleClose();
      refetch();
    },
    onError: (errors: unknown) => console.error({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Create Lead Provider'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      primaryActionText={'Save'}
    >
      <Container responsive={responsive}>
        <FieldInput
          {...form}
          label={'Name'}
          isResponsive
          isRequired
          input={{
            placeholder: 'Name of this lead provider',
          }}
          name={`createLeadProviderGlobalIntegrationForm.name`}
        />
        <Space height={16} />
        <FieldInput
          {...form}
          label={'Description'}
          isResponsive
          input={{
            multiline: true,
            placeholder: 'Description of this lead provider',
            style: {height: 240, paddingTop: 8},
          }}
          name={`createLeadProviderGlobalIntegrationForm.description`}
        />
        <Space height={16} />
        <FieldInput
          {...form}
          label={'URL'}
          isResponsive
          isRequired
          input={{
            placeholder: 'Zapier hook URL for this lead provider',
          }}
          name={`createLeadProviderGlobalIntegrationForm.url`}
        />
      </Container>
    </DrawerWithAction>
  );
};

export default CreateLeadProviderDrawer;
