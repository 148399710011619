// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {ResponsiveType, useNavigation, useResponsive, useState} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import MobileSearch from 'modules/App/components/MobileSearch';

import SearchBar from './SearchBar';

const Touchable = Styled.Touchable``;

const Container = Styled.View<{responsive: ResponsiveType}>`
  width: 100%;
  min-height: 68px;
  justify-content: center;
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  padding-horizontal: ${({responsive}) => (responsive.desktop ? '24' : '16')}px;
  padding-vertical: ${({responsive}) => (responsive.desktop ? '14' : '16')}px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeadingText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const DescriptionText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const MobileHeader = ({
  title,
  description,
  previousTitle,
  handlePrevious,
  HeaderActionElement,
  handleSearch,
  searchQuery = '',
  searchPlaceholder = 'Search',
  style,
}: {
  title?: string;
  description?: string;
  previousTitle?: string;
  handlePrevious?: () => void;
  HeaderActionElement?: React.ReactNode;
  handleSearch?: (text: string) => void;
  searchQuery?: string;
  searchPlaceholder?: string;
  style?: React.CSSProperties;
}) => {
  const responsive = useResponsive();
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  return (
    <React.Fragment>
      <Container responsive={responsive} style={style}>
        {previousTitle && (
          <TertiaryButton
            onPress={handlePrevious}
            text={previousTitle}
            iconLeft={Icon.AngleLeft}
            textColor={colors.blue.interactive}
            isResponsive
          />
        )}
        <Row>
          <HeadingText numberOfLines={1} responsive={responsive}>
            {title || ''}
          </HeadingText>
          <Space flex={1} />
          {handleSearch && (
            <TertiaryButton onPress={() => setIsSearchVisible((prev) => !prev)}>
              <Icon source={Icon.MagnifyingGlass} size={16} color={colors.gray.secondary} />
            </TertiaryButton>
          )}
          {handleSearch && HeaderActionElement && <Space width={16} />}
          {HeaderActionElement}
        </Row>
        {description && (
          <React.Fragment>
            <Space height={8} />
            <DescriptionText responsive={responsive}>{description}</DescriptionText>
          </React.Fragment>
        )}
      </Container>
      {handleSearch && (
        <MobileSearch
          searchQuery={searchQuery}
          setSearchQuery={handleSearch}
          isVisible={isSearchVisible}
          handleClose={() => setIsSearchVisible(false)}
          placeholder={searchPlaceholder}
        />
      )}
    </React.Fragment>
  );
};

const AdminAppPageHeader = ({
  title,
  description,
  previousTitle,
  handlePrevious,
  HeaderActionElement,
  handleSearch,
  searchQuery = '',
  searchPlaceholder = 'Search',
  style,
}: {
  title?: string;
  description?: string;
  previousTitle?: string;
  handlePrevious?: () => void;
  HeaderActionElement?: React.ReactNode;
  handleSearch?: (text: string) => void;
  searchQuery?: string;
  searchPlaceholder?: string;
  style?: React.CSSProperties;
}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigation();
  const handlePreviousNavigation = () => {
    if (handlePrevious) {
      handlePrevious();
    } else {
      navigator.goBack();
    }
  };
  if (!responsive.desktop) {
    return (
      <MobileHeader
        title={title}
        description={description}
        previousTitle={previousTitle}
        handlePrevious={handlePreviousNavigation}
        HeaderActionElement={HeaderActionElement}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        searchPlaceholder={searchPlaceholder}
        style={style}
      />
    );
  }

  return (
    <Container responsive={responsive} style={style}>
      <Row>
        {previousTitle && (
          <Touchable onPress={handlePreviousNavigation}>
            <HeadingText responsive={responsive} style={{color: colors.gray.tertiary}}>
              {`${previousTitle} /${' '}`}
            </HeadingText>
          </Touchable>
        )}
        <HeadingText responsive={responsive}>{title || ''}</HeadingText>
        <Space flex={1} />
        {handleSearch && (
          <SearchBar
            placeholder={searchPlaceholder}
            setSearchQuery={handleSearch}
            searchQuery={searchQuery}
            desktopWidth={280}
          />
        )}
        {handleSearch && HeaderActionElement && <Space width={16} />}
        {HeaderActionElement}
      </Row>
      {description && (
        <React.Fragment>
          <Space height={8} />
          <DescriptionText responsive={responsive}>{description}</DescriptionText>
        </React.Fragment>
      )}
    </Container>
  );
};

export default AdminAppPageHeader;
