// Libraries
import React from 'react';

// Supermove
import {Space, Styled, PhoneInput} from '@supermove/components';
import {useToast, useModal} from '@supermove/hooks';
import {PhoneNumberModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import FieldInput from '@shared/design/components/Field/FieldInput';
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ChangePrimaryPhoneNumberForm from '@shared/modules/Organization/forms/ChangePrimaryPhoneNumberForm';
import useChangePrimaryPhoneNumberMutation from '@shared/modules/Organization/hooks/useChangePrimaryPhoneNumberMutation';
import PrimaryPhoneNumberFields from 'modules/Company/CompanyDetails/components/PrimaryPhoneNumberFields';

const DescriptionText = Styled.Text`
  ${Typography.Body};
  color: ${colors.gray.secondary};
`;

const CalloutContainer = Styled.View`
`;

interface ChangePrimaryPhoneNumberDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  phoneNumber: PhoneNumberModel;
  organizationId: string;
  refetch: () => void;
}

const ChangePrimaryPhoneNumberDrawer = ({
  isOpen,
  handleClose,
  phoneNumber,
  organizationId,
  refetch,
}: ChangePrimaryPhoneNumberDrawerProps) => {
  const confirmChangePhoneNumberModal = useModal({name: 'Confirm Change Phone Number Modal'});
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Primary phone number changed successfully.',
  });

  const changePrimaryPhoneNumberForm = ChangePrimaryPhoneNumberForm.new({
    primaryPhoneNumberId: phoneNumber.id,
    organizationId,
  });

  const {form, submitting, handleSubmit} = useChangePrimaryPhoneNumberMutation({
    changePrimaryPhoneNumberForm,
    onSuccess: () => {
      successToast.handleToast();
      refetch();
      handleClose();
      confirmChangePhoneNumberModal.handleClose();
    },
    onError: (errors: unknown) => console.log({errors}),
  });

  const field = 'changePrimaryPhoneNumberForm';
  const calloutText = form?.values?.changePrimaryPhoneNumberForm?.phoneNumber
    ? 'Updating the phone number will delete the current number and unregister it from Twilio. The new number will be registered with Twilio when you save.'
    : 'Updating the phone number will make the current number inactive. It will no longer be able receive calls or text messages.';

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Update Phone Number'}
      isSubmitting={submitting}
      handleSubmit={confirmChangePhoneNumberModal.handleOpen}
      isDisabled={!form?.values?.changePrimaryPhoneNumberForm?.phoneNumber}
    >
      <CalloutContainer>
        <WarningCallout text={calloutText} />
      </CalloutContainer>
      <Space height={16} />
      <FieldInput
        index={0}
        component={PhoneInput}
        label={'Current Phone Number'}
        isResponsive
        input={{
          value: phoneNumber.number,
          disabled: true,
        }}
      />
      <Space height={16} />
      <DescriptionText>Enter an area code and search for a new phone number.</DescriptionText>
      <Space height={16} />
      <PrimaryPhoneNumberFields form={form} field={field} phoneNumber={phoneNumber.number} />
      <CautionModal
        isOpen={confirmChangePhoneNumberModal.isOpen}
        title={'Change primary phone number?'}
        message={'Any calls made to your previous primary phone number will not be received.'}
        handlePrimaryAction={handleSubmit}
        primaryActionText={'Confirm'}
        handleSecondaryAction={confirmChangePhoneNumberModal.handleClose}
        secondaryActionText={'Cancel'}
        isSubmitting={submitting}
      />
    </DrawerWithAction>
  );
};

export default ChangePrimaryPhoneNumberDrawer;
