// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';

interface UpdateLeadProviderGlobalIntegrationMutation {
  updateLeadProviderGlobalIntegrationForm: {
    leadProviderGlobalIntegrationId: number;
    name: string;
    description: string;
    status: 'active' | 'inactive';
    url: string;
  };
  onSuccess: () => void;
  onError: (error: MutationError[]) => void;
}

const useUpdateLeadProviderGlobalIntegrationMutation = ({
  updateLeadProviderGlobalIntegrationForm,
  onSuccess,
  onError,
}: UpdateLeadProviderGlobalIntegrationMutation) => {
  const form = useForm({
    initialValues: {
      updateLeadProviderGlobalIntegrationForm,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateLeadProviderGlobalIntegrationMutation.mutation,
    variables: {
      updateLeadProviderGlobalIntegrationForm: form.values.updateLeadProviderGlobalIntegrationForm,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateLeadProviderGlobalIntegrationMutation.mutation = gql`
    mutation useUpdateLeadProviderGlobalIntegrationMutation($updateLeadProviderGlobalIntegrationForm: UpdateLeadProviderGlobalIntegrationForm!) {
        response: updateLeadProviderGlobalIntegration(updateLeadProviderGlobalIntegrationForm: $updateLeadProviderGlobalIntegrationForm) {
            ${gql.errors}
            leadProviderGlobalIntegration {
                id
            }
        }
    }
`;

export default useUpdateLeadProviderGlobalIntegrationMutation;
