// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useNavigation, useQuery, useResponsive} from '@supermove/hooks';
import {CompanyModel, OrganizationModel} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import EmptyState from '@shared/design/components/EmptyState';
import FieldInput from '@shared/design/components/Field/FieldInput';
import FieldValue from '@shared/design/components/Field/FieldValue';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';
import SupermoveProductForm from '@shared/modules/SupermoveProduct/forms/SupermoveProductForm';
import useUpsertSupermoveProductMutation from '@shared/modules/SupermoveProduct/hooks/useUpsertSupermoveProductMutation';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';

const DrawerHeaderText = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const DrawerSubHeaderText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const UpdateTrucksDrawer = ({
  isOpen,
  handleClose,
  refetch,
  company,
}: {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
  company: CompanyModel;
}) => {
  const responsive = useResponsive();
  const {contractedTruckQuantity} = company;
  const supermoveProductForm = SupermoveProductForm.new({
    kind: SupermoveProductKind.OFFICE,
    status: SupermoveProductStatus.ACTIVE,
    companyId: company.id,
  });
  const {form, handleSubmit, submitting} = useUpsertSupermoveProductMutation({
    supermoveProductForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Update Trucks'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <WarningCallout
        text={"Updating the number of trucks requires an update to this organization's billing."}
      />
      <Space height={16} />
      <DrawerHeaderText responsive={responsive}>Trucks</DrawerHeaderText>
      <Space height={4} />
      <DrawerSubHeaderText responsive={responsive}>
        {`Update the number of contracted number of trucks for this organization.`}
      </DrawerSubHeaderText>
      <Space height={16} />
      <FieldInput
        label={'Current Number of Trucks'}
        isResponsive
        input={{
          disabled: true,
          value: contractedTruckQuantity ? _.toString(contractedTruckQuantity) : '0',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        name={`supermoveProductForm.contractedTruckQuantity`}
        label={'Updated Number of Trucks'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter updated number of trucks',
          setFieldValue: form.setFieldValue,
        }}
      />
    </DrawerWithAction>
  );
};

const TrucksPanelBody = ({organization}: {organization: OrganizationModel}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigation();
  const {officeAppProduct, company} = organization;
  const {contractedTruckQuantity} = company;

  if (!officeAppProduct) {
    return (
      <EmptyState.DescriptionText responsive={responsive} style={{textAlign: 'center'}}>
        {'Office App is not enabled for this organization. '}
        <LinkText
          responsive={responsive}
          onPress={() => navigator.navigate('CompanyDetailsProductsPage', {slug: params.slug})}
        >
          Navigate to products to enable it.
        </LinkText>
      </EmptyState.DescriptionText>
    );
  }

  return (
    <FieldValue isResponsive label={'Number of Trucks'} value={contractedTruckQuantity || '0'} />
  );
};

const CompanyDetailsOfficeAppGeneralPage = () => {
  const {params} = useNavigation();
  const updateTrucksDrawer = useDrawer({name: 'Update Trucks Drawer'});
  const {loading, data, refetch} = useQuery(CompanyDetailsOfficeAppGeneralPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });
  return (
    <CompanyDetailsPage
      selectedLabel={'office-app/general'}
      pageTitle={'Office App'}
      pageDescription={
        'Configure Office App including the contracted number of trucks for this company.'
      }
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <React.Fragment>
            <ActionPanel
              title={'Trucks'}
              BodyComponent={TrucksPanelBody}
              bodyComponentProps={{organization: data.organization}}
              ActionButtonComponent={data?.organization.officeAppProduct && TertiaryButton}
              actionButtonComponentProps={{
                iconLeft: Icon.Pen,
                text: 'Edit',
                onPress: updateTrucksDrawer.handleOpen,
              }}
            />
            <UpdateTrucksDrawer
              key={updateTrucksDrawer.key}
              isOpen={updateTrucksDrawer.isOpen}
              handleClose={updateTrucksDrawer.handleClose}
              refetch={refetch}
              company={data.organization.company}
            />
          </React.Fragment>
        )}
      </Loading>
    </CompanyDetailsPage>
  );
};

CompanyDetailsOfficeAppGeneralPage.query = gql`
  query CompanyDetailsOfficeAppGeneralPage($slug: String!) {
    ${gql.query}
    organization(slug: $slug) {
      id
      officeAppProduct: supermoveProduct(kind: "OFFICE") {
        id
      }
      company {
        id
        contractedTruckQuantity
      }
    }
  }
`;

export default CompanyDetailsOfficeAppGeneralPage;
