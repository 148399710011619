// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useNavigation,
  useQuery,
  useResponsive,
  ResponsiveType,
  useInlineFormMutation,
} from '@supermove/hooks';
import {SettingsModel, UserModel} from '@supermove/models';

// App
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import SettingsSection from '@shared/modules/Settings/components/SettingsSection';
import SettingsSectionOption from '@shared/modules/Settings/components/SettingsSectionOption';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';

const CommunicationSettingsContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

const CommunicationSettings = ({
  settings,
  viewer,
  refetch,
}: {
  settings: SettingsModel;
  viewer: UserModel;
  refetch: () => void;
}) => {
  const toggleSettingsIsEmailAppendViaSupermoveEnabledMutation = useInlineFormMutation({
    name: 'settingsForm',
    form: {
      settingsId: settings.id,
      updatedById: viewer.id,
    },
    mutation: gql`
      mutation useToggleSettingsIsEmailAppendViaSupermoveEnabledMutation($settingsForm: SettingsForm!) {
        response: toggleSettingsIsEmailAppendViaSupermoveEnabled(settingsForm: $settingsForm) {
          ${gql.errors}
          settings {
            id
            isEmailAppendViaSupermoveEnabled
          }
        }
      }
    `,
    onSuccess: refetch,
  });
  const toggleSettingsIsMovingEmailDomainEmailsEnabledMutation = useInlineFormMutation({
    name: 'settingsForm',
    form: {
      settingsId: settings.id,
      updatedById: viewer.id,
    },
    mutation: gql`
      mutation useToggleSettingsIsMovingEmailDomainEmailsEnabledMutation($settingsForm: SettingsForm!) {
        response: toggleSettingsIsMovingEmailDomainEmailsEnabled(settingsForm: $settingsForm) {
          ${gql.errors}
          settings {
            id
            isMovingEmailDomainEmailsEnabled
          }
        }
      }
    `,
    onSuccess: refetch,
  });
  return (
    <SettingsSection title={'Email'}>
      <SettingsSectionOption
        name={'Include Supermove Branding'}
        description={'Append “via Supermove” to the sender name for all emails.'}
        isEnabled={settings.isEmailAppendViaSupermoveEnabled}
        onPress={toggleSettingsIsEmailAppendViaSupermoveEnabledMutation.handleSubmit}
      />
      <Space height={16} />
      <SettingsSectionOption
        name={'Send from notify@moving.email'}
        description={'Send all emails from notify@moving.email.'}
        isEnabled={settings.isMovingEmailDomainEmailsEnabled}
        onPress={toggleSettingsIsMovingEmailDomainEmailsEnabledMutation.handleSubmit}
      />
    </SettingsSection>
  );
};

const CompanyModulesCommunicationPage = () => {
  const {params} = useNavigation();
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(CompanyModulesCommunicationPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });

  return (
    <CompanyDetailsPage
      selectedLabel={'modules/communication'}
      pageTitle={'Communication'}
      pageDescription={
        'Configure email and SMS settings. These changes will apply to the company and, if applicable, its branches.'
      }
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <CommunicationSettingsContainer responsive={responsive}>
            <CommunicationSettings
              settings={data.company.primaryOrganization.settings}
              viewer={data.viewer}
              refetch={refetch}
            />
          </CommunicationSettingsContainer>
        )}
      </Loading>
    </CompanyDetailsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyModulesCommunicationPage.query = gql`
  query CompanyModulesCommunicationPage($slug: String!) {
    ${gql.query}
    viewer {
      id
    }
    company(slug: $slug) {
      id
      primaryOrganization {
        id
        settings {
          id
          isEmailAppendViaSupermoveEnabled
          isMovingEmailDomainEmailsEnabled
        }
      }
    }
  }
`;

export default CompanyModulesCommunicationPage;
